export const Headache = ({ width = 40, height = 40, stroke = "#343A40" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_466_15516"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="40"
      height="40"
    >
      <rect width="40" height="40" fill="white" />
    </mask>
    <g mask="url(#mask0_466_15516)">
      <path
        d="M5.00071 1.66626L6.57808 4.82098C6.62458 4.91398 6.74396 4.94216 6.82715 4.87978L9.82675 2.63008C9.91448 2.56428 10.0408 2.59969 10.0815 2.70151L11.6674 6.66626M1.66761 9.16639L4.84088 11.5466C4.92406 11.609 5.04345 11.5808 5.08995 11.4878L6.57818 8.51164C6.62468 8.41863 6.74406 8.39046 6.82725 8.45284L10.0008 10.833M1.66748 19.9994H6.42103C6.53893 19.9994 6.61956 19.8803 6.57576 19.7708L5.07515 16.0188C5.03646 15.9221 5.09503 15.814 5.19721 15.7935L9.16741 14.9995M35.3218 22.3049C37.1995 20.1179 38.334 17.2746 38.334 14.1663C38.334 7.26269 32.7375 1.66626 25.834 1.66626C18.9305 1.66626 13.334 7.26269 13.334 14.1663C13.334 14.4147 13.3412 14.6615 13.3555 14.9064C13.3572 14.9357 13.3513 14.965 13.3382 14.9913L10.8601 19.9474C10.4065 20.8546 10.8616 21.9533 11.8238 22.2739L15.5232 23.5071C15.5904 23.5294 15.6361 23.5919 15.6372 23.6628L15.7458 30.8581C15.7595 31.7686 16.5016 32.4996 17.4123 32.4996H22.6916C22.7783 32.4996 22.8505 32.5659 22.8576 32.6523L23.3213 38.1803C23.3286 38.2666 23.4008 38.3329 23.4875 38.3329H36.4856C36.5835 38.3329 36.6601 38.2493 36.6516 38.1518L35.2818 22.4288C35.2778 22.3838 35.2923 22.3391 35.3218 22.3049Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

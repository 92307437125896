export const WeightLoss = ({
  width = 38,
  height = 38,
  fill = "black",
  stroke = "#495057",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 300 328"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M133.5 17.05C79.7 20.15 17.65 38.8 11.35 53.7 9 59.35 9.7 65 27 177.25c18.05 117.1 18 116.9 24.1 122.1 6.9 5.9 4.25 5.75 104.9 5.45l92.5-.3 4.7-2.8c9.45-5.5 7.8 1.85 27.35-124.35C290.15 115.3 298 62.95 298 61.05c.05-12.25-9.95-18.85-45-29.9-35.15-11.1-80.05-16.4-119.5-14.1zm48.5 19c33.95 3.1 95 18.95 97.25 25.25.45 1.25-33.7 223.5-34.45 224.25-.8.8-181.15.5-181.9-.3C61.05 283.3 27.8 61.8 29.15 60.5c2.75-2.65 29.2-11.65 48.2-16.45 31.25-7.85 72.05-10.95 104.65-8z"
        stroke={stroke}
        fill={fill}
      />
      <path
        d="M133 49.05c-32 2.8-66.7 12.5-72.15 20.2-3.15 4.35-4.15 35.1-1.35 42.35 1.7 4.5 7.9 10.85 12.1 12.45 5.65 2.15 162 1.2 166.35-1 9.5-4.85 12.05-11.6 12.05-31.75 0-22.35-1.8-25.2-19.45-30.9-29.95-9.7-67.15-14-97.55-11.35zm49.05 19.5c13.65 1.7 29.3 5 40.95 8.65l7.5 2.4v26.9l-30.25.25-30.25.3 5.7-9.3c7.45-12.2 7.25-17.9-.6-20.9-5.75-2.2-8.45.2-17.75 15.75l-8.6 14.4H113.5c-41.65 0-37.65 1.65-37.3-15.15l.3-12.3 9.5-2.8c30.4-9 65.55-12 96.05-8.2z"
        stroke={stroke}
        fill={fill}
      />
      <path
        d="M72.5 140.9c-14.3 6.6-14.55 9-5.95 64.95 8.2 53.45 9.1 55.95 22.1 59.7 8.6 2.55 128.45 1.7 133.35-.9 4.9-2.6 7.9-5.55 10.1-9.85 2.45-4.75 16.25-93.45 15.3-98.4-1.15-6.15-5.9-12.25-11.65-14.95L230.5 139l-77 .05c-72.85 0-77.2.1-81 1.85zM228 157.95c1.35.9-10.55 85.9-12.55 89.1-.95 1.55-121.95 1.25-123.15-.3-1.75-2.25-14.3-87.05-13.1-88.45 1.3-1.6 146.3-1.9 148.8-.35z"
        stroke={stroke}
        fill={fill}
      />
    </g>
  </svg>
);

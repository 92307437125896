import { Container, Space, Stack, Text, Title } from "@mantine/core";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MedicalClipboard } from "../icons/MedicalClipboard.tsx";
import { Microphone } from "../icons/Microphone.tsx";
import { CurrentProfile } from "../integrations/bonfhir.tsx";
import { Button } from "../ui-components/Button.tsx";
import classNames from "./Appointment.module.css";

export function Home() {
  const practitioner = useContext(CurrentProfile);
  const navigate = useNavigate();
  return (
    <Container size="md" p="lg" className={classNames.root}>
      <Stack gap="xl" px="lg" py="xl" align="center" w="90%">
        <Title order={1}>Welcome to Cade!</Title>
        <Text fz="xl" style={{ marginLeft: 20 }}>
          It is great to meet you today, Dr{" "}
          {`${practitioner?.name?.[0].given} ${practitioner?.name?.[0].family} `}{" "}
          and Team! My name is Cade and I am here to assist you with
          administrative work around your patient visits.
          <Space h="lg" />
          I can help you to:
          <Space h="lg" />
          <Button
            onClick={() => {
              navigate(`/new-appointment/prepare`);
            }}
            variant="outline"
            color="buttonPrimary"
            size="md"
            style={{ width: 250 }}
          >
            <Text>
              <MedicalClipboard height={15} width={15} /> Prepare a visit
            </Text>
          </Button>
          <Button
            onClick={() => {
              navigate(`/new-appointment/scribe`);
            }}
            variant="outline"
            color="buttonPrimary"
            size="md"
            style={{ marginLeft: 10, width: 250 }}
          >
            <Text>
              <Microphone height={15} width={15} /> Scribe a visit
            </Text>
          </Button>
          <Space h="lg" />
          {/* See{" "}
          <a href="https://www.loom.com/share/7b31ad126be1450f967a6a2d74a8c4b0?sid=674a8261-34b5-4401-9400-18d9b4524428">
            this
          </a>{" "}
          video */}
          <Space h="lg" />
          When you involve me in preparing a visit, I can significantly improve
          the quality of the documentation and coding by also taking into
          account the patient's health history.
          <Space h="lg" />
          You can use me for in office visits and virtual visits. I will send
          your patients a secure video link and make sure they join the visit at
          the right time.
          <Space h="lg" />
          Please share any questions or suggestions for improvement by chatting
          with me in the bubble in the bottom right corner.
          <Space h="lg" />
        </Text>
        {/* <div
          style={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            overflow: "hidden",
            maxWidth: "100%",
          }}
        >
          <iframe
            src="https://www.loom.com/embed/7b31ad126be1450f967a6a2d74a8c4b0?sid=674a8261-34b5-4401-9400-18d9b4524428"
            frameBorder="0"
            // webkitallowfullscreen
            // mozallowfullscreen
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </div> */}
        <Button
          onClick={() => {
            navigate(`/new-appointment/scribe`);
          }}
          variant="filled"
          color="buttonPrimary"
          size="md"
          style={{ width: "40%" }}
        >
          Start Cade now
        </Button>
      </Stack>
    </Container>
  );
}

import { AppShell } from "@mantine/core";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { LayoutTheme } from "./LayoutTheme";
import { NavBar } from "./NavBar";
import { TitleBar } from "./TitleBar";

import classnames from "./AppShell.module.css";

export function AppShellView() {
  const { shouldShowNavbar, rootBackground } = useContext(LayoutTheme);

  return (
    <AppShell
      header={{ height: 90 }}
      navbar={{
        breakpoint: "sm",
        width: 300,
      }}
      padding="md"
    >
      <AppShell.Header p="md">
        <TitleBar />
      </AppShell.Header>
      <AppShell.Navbar display={!shouldShowNavbar ? "none" : ""}>
        <NavBar />
      </AppShell.Navbar>
      <AppShell.Main
        className={shouldShowNavbar ? classnames.main : classnames.mainFull}
        bg={rootBackground}
      >
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}

export const forms = {
  skinIssues: "skin-issues",
  jointPain: "joint-pain",
  coldFlu: "cold-flu",
  backAndNeckPain: "back-and-neck-pain",
  headacheMigraine: "headache-migraine",
  diabetes: "diabetes",
  bloodPressure: "blood-pressure",
  weightLoss: "weight-loss",
  menopause: "menopause",
  sleepingProblems: "sleeping-problems",
  heartProblems: "heart-problems",
  prescriptionRefill: "prescription-refill",
};

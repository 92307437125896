import {
  Appointment,
  Observation,
  Retrieved,
  reference,
} from "@bonfhir/core/r4b";
import { useFhirSearch } from "@bonfhir/query/r4b";
import { Space, Table, Text, Title } from "@mantine/core";

type Props = {
  appointment: Retrieved<Appointment> | undefined;
};

export function PatientIntakeTab({ appointment }: Props) {
  const observationsQuery = useFhirSearch("Observation", (query) => {
    return query
      .focus(reference(appointment))
      .code({ system: "http://loinc.org", code: "8716-3" });
  });
  const { virtual } = JSON.parse(
    appointment?.patientInstruction ?? `{patientLink: ""}`,
  );
  const observations = observationsQuery.data?.searchMatch() ?? [];

  const intake = observations.find(
    (o: Observation) =>
      o.code.coding &&
      o.code.coding[0].system === "http://loinc.org" &&
      o.code.coding[0].code === "8716-3",
  );
  // const medicalHistory = observations.filter(o => o.valueString?.includes('mhistory')).map((o, i) => {
  //   const type = ((o.valueString ?? "").split(": ")[0])
  //     .replace(/_/g, ' ')
  //     .replace("mhistory", "")
  //   const value = o.valueString?.split(": ")[1].replace(/_/g, ' ').replace(/\[/g, '').replace(/"/g, '').replace(/\]/g, ',');
  //   return [`appointment-observation-${i}`, type, value];
  // });

  // const currentCondition = observations.filter(o => !o.valueString?.includes('mhistory')).map((o, i) => {
  //   const type = ((o.valueString ?? "").split(": ")[0])
  //     .replace(/_/g, ' ')
  //     .replace("pdata", "Patient data");
  //   const value = o.valueString?.split(": ")[1].replace(/_/g, ' ').replace(/\[/g, '').replace(/"/g, '').replace(/\]/g, ',') ?? "";
  //   return [`appointment-observation-${i}`, type, value];
  // });

  /* <Alert
          variant="transparent"
          color="red"
          styles={{
            message: {
              color: "red",
            },
          }}
          title={
            isSuccess
              ? "AI Scribe was successful."
              : "Unfortunately, AI Scribe didn't work."
          }
          icon={<IconInfoCircle />}
        >
          {isSuccess
            ? "Please ensure that you review the contents of all outputs and confirm that they accurately represent the consultation"
            : "Please add your visit notes and review them before sending them to the patient."}
        </Alert>} */
  return (
    <div>
      {appointment?.status !== "fulfilled" && virtual && (
        //   <Alert
        //   variant="transparent"
        //   color="#7E4DD2"
        //   styles={{
        //     message: {
        //       color: "#7E4DD2",
        //     },
        //   }}
        //   title={
        //     isSuccess
        //       ? "AI Scribe was successful."
        //       : "Unfortunately, AI Scribe didn't work."
        //   }
        //   icon={<IconInfoCircle />}
        // >
        //   {isSuccess
        //     ? "Please ensure that you review the contents of all outputs and confirm that they accurately represent the consultation"
        //     : "Please add your visit notes and review them before sending them to the patient."}
        // </Alert>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#7E4DD2",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              backgroundColor: "red",
              color: "white",
              marginRight: "10px",
            }}
          >
            !
          </div>
          <Text>
            <b>Instructions for the virtual visit:</b>
            <br />
            Please turn on your camera and microphone and make sure to leave the
            call at the end by pressing the button at the bottom right corner of
            the video call.
          </Text>
        </div>
      )}
      <Title order={3}>Patient request</Title>
      <Space h="md" />
      <Table>
        {/* <thead>
          <tr>
            <th colSpan={2} style={{ textAlign: 'left' }}>Medical History</th>
          </tr>
        </thead> */}
        <Text>{intake?.valueString}</Text>
        {/* {medicalHistory?.map(r => {
            return (
              <tr key={r[0]}>
                <td style={{ width: '66.67%', textAlign: 'left' }}>{r[1]}</td>
                <td style={{ width: '33.33%', textAlign: 'left' }}><b>{r[2]}</b></td>
              </tr>
            );
          })}
        </tbody>
        <br/>
        <thead>
          <tr>
            <th colSpan={2} style={{ textAlign: 'left' }}>Current Complaints</th>
          </tr>
        </thead>
        <tbody>
        {currentCondition?.map(r => {
            return (["appointment slot", "patient id", "state", "tz"].includes(r[1])) ? "" : (
              <tr key={r[0]}>
                <td style={{ width: '66.67%', textAlign: 'left' }}>{r[1]}</td>
                <td style={{ width: '33.33%', textAlign: 'left' }}><b>{r[2]}</b></td>
              </tr>
            );
          })} */}
      </Table>
    </div>
  );
}

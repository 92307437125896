import { z } from "zod";
import * as OAuth2 from "./oauth2.tsx";

export const Profile = z.object({
  /**
   * An optional display name for the user's profile. This is often a formatted
   * `HumanName` for user-like profiles (e.g.: `Patient` or `Practitioner`)
   *
   * @example
   * "Beverly Crusher"
   */
  display: z.string().trim().min(1).optional(),

  /**
   * A FHIR `Reference` to the user's profile as a string containing the
   * resource type name and the resource Id separated by a forward slash.
   * Resource Ids in Medplum are UUIDs.
   *
   * @example
   * "Patient/123e4567-e89b-12d3-a456-426614174000"
   */
  reference: z.string().trim().min(1),
});
export type Profile = z.infer<typeof Profile>;

/**
 * Medplum extends the typical OAuth2 token exchange response with a profile
 * containing a reference to the user's profile as a FHIR resource (e.g.: a
 * `Patient` or `Practitioner` reference of the )
 */
export const TokenExchangeResponseBody =
  OAuth2.TokenExchangeSuccessResponseBody.extend({
    profile: Profile,
  });
export type TokenExchangeResponseBody = z.infer<
  typeof TokenExchangeResponseBody
>;

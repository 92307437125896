import {
  Appointment,
  AppointmentFhirSearchBuilder,
  reference,
} from "@bonfhir/core/r4b";
import { Stack, Text, Title } from "@mantine/core";
import { endOfDay, format, subMinutes } from "date-fns";
import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CurrentProfile } from "../integrations/bonfhir.tsx";
import { AppointmentTable } from "../ui-components/AppointmentList.tsx";
import { Button } from "../ui-components/Button.tsx";

export function Appointments() {
  const practitioner = useContext(CurrentProfile);
  const navigate = useNavigate();
  // const savePractitionerTransaction = useFhirTransactionMutation();

  // useEffect(()=> {
  //   if(!practitioner.active && savePractitionerTransaction) {
  //     alert("Please go the Availabilities page to create your slots");
  //     savePractitionerTransaction.mutate((transaction) => {
  //       const profile = practitioner as unknown as Practitioner;
  //       profile.active = true;
  //       transaction.update(profile);
  //     });
  //   }
  // }, [practitioner, savePractitionerTransaction])

  const dateFormat = "yyyy-MM-dd'T'HH:mm:ss";
  const now = new Date().toISOString();
  const formattedDate = format(
    subMinutes(new Date(now), 120),
    "yyyy-MM-dd'T'HH:mm:ss",
  );
  const todaysEnd = endOfDay(now);
  const todaysEndFormatter = format(todaysEnd, dateFormat);

  const todaysAppointments = useCallback(
    (builder: AppointmentFhirSearchBuilder): AppointmentFhirSearchBuilder => {
      return builder
        .actor(reference(practitioner))
        ._include("Appointment", "patient")
        .filterSearchParameter(
          `date gt ${formattedDate} and date lt ${todaysEndFormatter} `,
        )
        .filterSearchParameter(
          `status ne "cancelled" and status ne "fulfilled" and status ne "pending"`,
        );
    },
    [practitioner],
  );

  const futureAppointments = useCallback(
    (builder: AppointmentFhirSearchBuilder): AppointmentFhirSearchBuilder => {
      return builder
        .actor(reference(practitioner))
        ._include("Appointment", "patient")
        .filterSearchParameter(`date gt ${todaysEndFormatter}`)
        .filterSearchParameter(
          `status ne "cancelled" and status ne "fulfilled" and status ne "pending"`,
        );
    },
    [practitioner],
  );

  return (
    <Stack gap="xl" px="lg" py="xl">
      <Title order={2}>Upcoming visits</Title>
      <Text fz="xl" c="textSecondary">
        Here you will find requests from patients who would like to be treated
        today or in the next few days, or who would like immediate treatment.
      </Text>
      <Stack>
        <Text c="textSecondary" size="xl" fw="bold">
          TODAY
        </Text>
        <AppointmentTable
          query={todaysAppointments}
          future={false}
          appointmentActions={(appointment: Appointment) => {
            return (
              <Button
                onClick={() => {
                  navigate(`/appointment/${appointment.id}`);
                }}
                variant="outline"
                color="black"
              >
                Start Consultation
              </Button>
            );
          }}
        />
      </Stack>
      <Stack mt="6rem">
        <Text c="textSecondary" size="xl" fw="bold">
          FUTURE VISITS
        </Text>
        <AppointmentTable
          query={futureAppointments}
          future={true}
          appointmentActions={(appointment: Appointment) => {
            return (
              <Button
                onClick={() => {
                  navigate(`/appointment/${appointment.id}`);
                }}
                variant="outline"
                color="black"
              >
                Start Consultation
              </Button>
            );
          }}
        />
      </Stack>
    </Stack>
  );
}

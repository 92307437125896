import { FetchFhirClient } from "@bonfhir/core/r4b";
import { MantineRenderer } from "@bonfhir/mantine/r4b";
import { FhirQueryProvider } from "@bonfhir/query/r4b";
import { FhirUIProvider } from "@bonfhir/react/r4b";
import { Box, Center, Loader, MantineProvider, Text } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/tiptap/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useState } from "react";
import { AuthProvider } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { AppConfigContext, AppConfiguration } from "./app-configuration.ts";
import { AppShellView } from "./components/AppShell.tsx";
import { LayoutThemeProvider } from "./components/LayoutTheme.tsx";
import { ProfileProvider } from "./integrations/bonfhir";
import * as Medplum from "./integrations/medplum.ts";
import { TokenExchanger, TokenResponseForm } from "./integrations/oauth2";
import { WhenAuthenticated } from "./integrations/openid-connect";
import { CascadeTheme } from "./theme.ts";
import { WebStorageStateStore } from "oidc-client-ts";

type AppProps = {
  config: AppConfiguration;
};

function App({ config }: AppProps) {
  const navigate = useNavigate();
  const [client] = useState(() => new QueryClient());

  return (
    <AppConfigContext.Provider value={config}>
      <QueryClientProvider client={client}>
        <MantineProvider theme={CascadeTheme}>
          <AuthProvider
            authority={config.openIdConnect.discoveryURL}
            client_id={config.openIdConnect.clientId}
            redirect_uri={document.location.origin}
            scope={config.openIdConnect.scopes?.join(" ") ?? "email openid"}
            userStore={new WebStorageStateStore({ store: window.localStorage })}
          >
            <WhenAuthenticated>
              {(currentUser, signout) => (
                <TokenExchanger
                  accessToken={currentUser.access_token}
                  clientId={config.tokenExchanger.clientId}
                  onError={(error, setResult) =>
                    renderTokenExchangeError(error, setResult, () =>
                      signout().then(() => navigate("/")),
                    )
                  }
                  pending={renderCodedMessage}
                  tokenEndpoint={config.tokenExchanger.tokenURL}
                  tokenPayloadParser={Medplum.TokenExchangeResponseBody.parse}
                >
                  {(exchangedToken: Medplum.TokenExchangeResponseBody) => (
                    <FhirQueryProvider
                      fhirClient={
                        new FetchFhirClient({
                          auth: `Bearer ${exchangedToken.access_token}`,
                          baseUrl: config.fhirServerBaseURL,
                          onError(response) {
                            if (response.status === 401) return signout();
                          },
                        })
                      }
                      queryClient={client}
                    >
                      <FhirUIProvider
                        renderer={MantineRenderer}
                        onNavigate={({ target, aux }) => {
                          if (aux) {
                            window.open(target, "_blank");
                          } else {
                            navigate(target);
                          }
                        }}
                      >
                        <ProfileProvider profile={exchangedToken.profile}>
                          <LayoutThemeProvider>
                            <AppShellView />
                          </LayoutThemeProvider>
                        </ProfileProvider>
                      </FhirUIProvider>
                      <ReactQueryDevtools client={client} />
                    </FhirQueryProvider>
                  )}
                </TokenExchanger>
              )}
            </WhenAuthenticated>
          </AuthProvider>
        </MantineProvider>
      </QueryClientProvider>
    </AppConfigContext.Provider>
  );
}

function renderTokenExchangeError(
  error: string,
  setResult: (result: Medplum.TokenExchangeResponseBody) => void,
  signout?: () => void,
) {
  return (
    <div>
      <h1>Token exchange failed</h1>
      <code>{error}</code>
      <hr />
      <TokenResponseForm<Medplum.TokenExchangeResponseBody>
        onAbort={signout}
        onSubmit={setResult}
      />
    </div>
  );
}

function renderCodedMessage(message: string) {
  return (
    <Box>
      <Center flex={1}>
        <Loader />
        <Text>{message}</Text>
      </Center>
    </Box>
  );
}

export default App;

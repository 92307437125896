export const ColdAndFlu = ({ width = 40, height = 40, stroke = "#495057" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0182 1.66681C15.7631 1.66681 15.1751 5.03839 14.3406 9.25289C13.673 12.6245 12.668 14.7371 11.8371 15.9961C10.1682 18.5248 6.07165 20.5476 6.73924 25.268C7.40684 29.9881 12.3935 30.0443 14.3406 29.4825L16.2732 28.8606M16.2732 28.8606C16.2265 28.7805 16.1822 28.6986 16.1403 28.615L15.9996 28.3335C15.1824 26.6991 13.512 25.6668 11.6848 25.6668H11.3329M16.2732 28.8606C17.0427 30.1795 18.4589 31.0001 19.9996 31.0001C21.6339 31.0001 23.1279 30.0768 23.8589 28.615L23.9997 28.3335C24.8169 26.6991 26.4874 25.6668 28.3147 25.6668H28.6662M19.9812 1.66681C24.2364 1.66681 24.8244 5.03839 25.6589 9.25289C26.3264 12.6245 27.3314 14.7371 28.1624 15.9961C29.8312 18.5248 33.9277 20.5476 33.2602 25.268C32.5926 29.9881 27.6061 30.0443 25.6589 29.4825L23.8097 28.687M15.6231 20.6633V20.6105M23.9564 20.6631V20.6101M19.9997 16.7196V16.6667M14.7961 32.074L13.1571 35.352C12.4722 36.7218 13.4683 38.3335 14.9997 38.3335C16.5312 38.3335 17.5272 36.7218 16.8424 35.352L15.2033 32.074C15.1194 31.9063 14.88 31.9063 14.7961 32.074Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

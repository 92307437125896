import {
  ActionIcon,
  CopyButton,
  Group,
  Stack,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import {
  IconBold,
  IconCheck,
  IconCode,
  IconCopy,
  IconH1,
  IconH2,
  IconH3,
  IconItalic,
  IconList,
  IconListNumbers,
} from "@tabler/icons-react";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect } from "react";
import { Button } from "./Button";
import classNames from "./Documentation.module.css";

interface MyEditorProps {
  editor: Editor | null;
  initialContent: string;
  label?: string;
  value: string;
  onChange: (text: string) => void;
  disabled: boolean;
  placeholder: string;
  classNames?: {
    input?: string;
    wrapper?: string;
    noFocusOutline?: string;
  };
  minRows?: number;
  autosize?: boolean;
}

const MyEditor = ({
  editor,
  label,
  value,
  placeholder,
  classNames,
  disabled,
  minRows = 4, // Default value for minRows
  autosize = false, // Default value for autosize
}: MyEditorProps) => {
  useEffect(() => {
    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [value, editor]);

  useEffect(() => {
    if (editor?.options) {
      editor.options.editable = !disabled;
    }
  }, [disabled]);

  if (!editor) {
    return null;
  }

  return (
    <div className={classNames?.wrapper}>
      {label && <label>{label}</label>}
      <div
        style={{ position: "relative", overflowY: "auto", maxHeight: "600px" }}
      >
        <div
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 10,
            padding: 8,
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Group>
            <ActionIcon
              onClick={() =>
                !disabled && editor.chain().focus().toggleBold().run()
              }
              variant={editor.isActive("bold") ? "filled" : "outline"}
            >
              <IconBold size={18} />
            </ActionIcon>
            <ActionIcon
              onClick={() =>
                !disabled && editor.chain().focus().toggleItalic().run()
              }
              variant={editor.isActive("italic") ? "filled" : "outline"}
            >
              <IconItalic size={18} />
            </ActionIcon>
            <ActionIcon
              onClick={() =>
                !disabled &&
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
              variant={
                editor.isActive("heading", { level: 1 }) ? "filled" : "outline"
              }
            >
              <IconH1 size={18} />
            </ActionIcon>
            <ActionIcon
              onClick={() =>
                !disabled &&
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              variant={
                editor.isActive("heading", { level: 2 }) ? "filled" : "outline"
              }
            >
              <IconH2 size={18} />
            </ActionIcon>
            <ActionIcon
              onClick={() =>
                !disabled &&
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
              variant={
                editor.isActive("heading", { level: 3 }) ? "filled" : "outline"
              }
            >
              <IconH3 size={18} />
            </ActionIcon>
            <ActionIcon
              onClick={() =>
                !disabled && editor.chain().focus().toggleBulletList().run()
              }
              variant={editor.isActive("bulletList") ? "filled" : "outline"}
            >
              <IconList size={18} />
            </ActionIcon>
            <ActionIcon
              onClick={() =>
                !disabled && editor.chain().focus().toggleOrderedList().run()
              }
              variant={editor.isActive("orderedList") ? "filled" : "outline"}
            >
              <IconListNumbers size={18} />
            </ActionIcon>
            <ActionIcon
              onClick={() =>
                !disabled && editor.chain().focus().toggleCodeBlock().run()
              }
              variant={editor.isActive("codeBlock") ? "filled" : "outline"}
            >
              <IconCode size={18} />
            </ActionIcon>
          </Group>
        </div>
        <EditorContent
          editor={editor}
          disabled={disabled}
          className={classNames?.input}
          style={{
            minHeight: autosize ? `${minRows * 24}px` : "auto",
          }}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

type Props = {
  title?: string;
  label?: string;
  description?: string;
  content: string;
  onChange: (text: string) => void;
  disabled: boolean;
  placeholder: string;
  copy?: boolean;
};

export function DocumentationSection({
  title,
  description,
  label,
  content,
  onChange,
  disabled,
  placeholder,
  copy = true,
}: Props) {
  const editor = useEditor({
    extensions: [StarterKit],
    content: content,
    editable: !disabled,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
  });

  const click = (copy: () => void) =>
    title === "Visit Notes"
      ? navigator.clipboard.write([
          new ClipboardItem({
            "text/html": new Blob([editor?.getHTML() ?? content], {
              type: "text/html",
            }),
            "text/plain": new Blob([editor?.getHTML() ?? content], {
              type: "text/plain",
            }),
          }),
        ])
      : copy();

  return (
    <Stack gap="md" w="100%">
      <Title order={2}>{title}</Title>
      <Text size="sm">{description}</Text>
      {["Visit Notes", "Coding Support"].includes(title ?? "") ? (
        <MyEditor
          editor={editor}
          initialContent={content}
          label={label}
          value={content}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          classNames={{
            input: disabled ? "" : classNames.textAreaInput,
            wrapper: classNames.textAreaRoot,
          }}
        />
      ) : (
        <Textarea
          minRows={4}
          autosize
          label={label}
          value={content}
          onChange={(e) => onChange(e.currentTarget.value)}
          classNames={{
            input: classNames.textAreaInput,
            wrapper: classNames.textAreaRoot,
          }}
          disabled={disabled}
          placeholder={placeholder}
        />
      )}

      {copy && (
        <CopyButton value={content}>
          {({ copied, copy }) => (
            <Button
              variant="light"
              size="sm"
              leftSection={
                <ActionIcon
                  color={copied ? "teal" : "gray"}
                  variant="subtle"
                  onClick={() => click(copy)}
                >
                  {copied ? (
                    <IconCheck style={{ width: 24 }} />
                  ) : (
                    <IconCopy style={{ width: 24 }} />
                  )}
                </ActionIcon>
              }
              color="buttonDisabled"
              onClick={() => click(copy)}
            >
              {copied ? "Copied" : "Copy"}
            </Button>
          )}
        </CopyButton>
      )}
    </Stack>
  );
}

export const PrescriptionRefill = ({
  width = 900,
  height = 1170,
  stroke = "currentColor",
  fill = "#FFF",
  ...props
}) => (
  <svg viewBox="0 0 900 1050" width={width} height={height} {...props}>
    <g>
      <path
        d="M226 49.1c-95.9.4-109.1.7-113.6 2.1-6.7 2.1-15.7 6.4-21.3 10.2-5.7 3.7-21.3 19.3-23.4 23.2-4.5 8.3-8 16.3-10 22.2l-2.2 6.7v950l2.2 6.6c2 6.1 6.1 15.4 9.9 22.2 2.5 4.6 14.1 16.3 21.3 21.5 9 6.7 17.5 10.5 30.3 13.6 6.1 1.5 35.3 1.6 337.3 1.6 356.1 0 335.8.3 349-4.7 16.6-6.3 30.7-17.4 40.4-32 3.4-5.1 4.8-8.1 9.3-19.3 3.9-9.7 3.8-1.9 3.8-484.9 0-481.2 0-474.5-3.8-484.1-.5-1.4-1.8-4.5-2.8-7-8.6-21.2-30.4-40.2-52.8-46-7.5-2-12-2-236.3-2.3-125.9-.1-277.6 0-337.3.4zm559.7 71.1c1.1 1.3 2.6 3.3 3.2 4.5.8 1.6 1.1 123.8 1.1 463.6 0 395.9-.2 461.9-1.4 464.8-.8 1.9-2.8 4.2-4.4 5.2-2.8 1.6-20.3 1.7-327.2 1.7H132.7l-3.3-2.1c-2.2-1.3-3.7-3.3-4.4-5.7-.8-2.6-1-142.2-.8-465.9l.3-462.2 2.9-3 3-3.1h653.3l2 2.2z"
        stroke={stroke}
        fill={fill}
      />
      <path
        d="M254 186.9c-19.2 1.9-35.3 10.8-47.1 26.1-5.4 7.1-6.8 9.7-11.2 21-2 5.4-2.1 7.3-2.6 73-.3 37.1-.4 99.9-.1 139.5l.5 72 2.3 4.7c2.4 5.1 8.9 12.3 13.5 15.2 7.1 4.4 19.6 6 27.4 3.6 8.8-2.8 16.7-9.1 20.6-16.5 4.2-7.8 4.6-13.4 4.6-66.3.1-42.7.3-50.2 1.5-50.2 1.7 0 108.6 106.6 108.6 108.3 0 .7-11.3 12.5-25.1 26.2-13.7 13.8-26.5 27-28.4 29.5-4 5.5-7.5 14.9-7.5 20.5 0 2.3.9 6.8 2 10.1 7 20.4 28.6 29.9 47.5 20.9 5-2.4 10.2-7.1 32.8-29.7 15.2-15.2 27.6-26.8 28.6-26.8 1.1 0 13.5 11.7 28.7 26.8 22.5 22.5 27.9 27.3 32.9 29.7 14.7 7 32.5 2.6 42.1-10.4 3.7-5 7.4-15.3 7.4-20.6 0-5.2-3.7-15.3-7.4-20.4-1.9-2.5-14.8-15.8-28.5-29.6-13.8-13.7-25.1-25.5-25.1-26.1 0-.7 11.9-13.1 26.4-27.7 14.5-14.5 27.5-28.2 28.8-30.3 3-4.8 5.8-13.5 5.8-17.9 0-3.9-3.4-14.7-5.9-19-2.6-4.5-12.6-12.1-18.4-14-9.9-3.3-20.2-1.8-29.2 4.1-2.7 1.8-16.5 14.8-30.5 28.9-14 14-26.2 25.5-27 25.5-1.8 0-65-63-65-64.7 0-.7 1.8-1.7 4-2.3 2.1-.6 4.2-1.5 4.5-2 .3-.6 1.3-1 2-1 3.4 0 19.4-11 27.8-19 7.8-7.4 17.7-18.8 17.7-20.4 0-.3 1.3-2.4 2.8-4.8 2.8-4.2 7.9-15.7 10.2-22.8 7.8-24.6 6.2-54.3-4.3-77.1-1.3-3-2.7-6.1-3.1-6.9-2.8-6.3-11.2-17.9-18.5-25.3-15.8-16.3-33.8-26.5-56.6-32.2-5.9-1.4-12.9-1.8-44.5-2-20.6-.2-40.9 0-45 .4zm82 71.3c28.1 12.9 34.9 47.3 13.5 68.3-5.9 5.9-13.8 10-21.6 11.5-7.5 1.4-64.1 1.1-65.1-.4-.4-.6-.8-19.2-.8-41.3 0-31.1.3-40.3 1.3-40.7.6-.3 16.1-.4 34.2-.3 32.9.3 33 .3 38.5 2.9z"
        stroke={stroke}
        fill={fill}
      />
      <path
        d="M221 739c-9.6 1.3-19.2 8.4-24.6 18.4-2.5 4.6-2.8 6-2.8 15.1s.3 10.5 2.8 15c5.4 9.7 13 15.8 22.5 18.1 7.4 1.8 467.4 2.1 474.9.3 13.1-3 22.3-11.9 25.9-24.9 1.6-5.8 1.6-10.2-.1-17.1-2.4-9.8-9.5-18.3-19.3-23.1l-4.8-2.3-234.5-.1c-129-.1-237 .2-240 .6z"
        stroke={stroke}
        fill={fill}
      />
      <path
        d="M216.5 923.9c-7.3 3-10.2 5-14.3 9.6-7.4 8.4-8.6 11.7-8.5 23 0 8.8.3 10.6 2.6 15 2.8 5.4 9.4 12.2 14.2 14.8 9.3 5-4.7 4.7 246.3 4.7 222.2 0 234-.1 239.5-1.8 3.1-1 6.3-2.4 6.9-3 .7-.7 1.6-1.2 2.1-1.2 1.4 0 9.5-8.7 11.4-12.3 6-11.1 5.4-23.7-1.4-34.3-4.8-7.5-8.6-10.5-18.6-14.8-3.4-1.5-23.9-1.6-240-1.5-217.9.1-236.5.2-240.2 1.8z"
        stroke={stroke}
        fill={fill}
      />
    </g>
  </svg>
);

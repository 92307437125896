import { createBrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import { appConfiguration } from "./app-configuration.ts";
import { AppointmentConfirmation } from "./pages/AppointmentConfirmation.tsx";
import { AppointmentPage } from "./pages/AppointmentPage.tsx";
import { Appointments } from "./pages/Appointments.tsx";
import { AvailabilitiesPage } from "./pages/Availabilities.tsx";
import { DebugPage } from "./pages/DebugPage.tsx";
import { Home } from "./pages/Home.tsx";
import { Me } from "./pages/Me.tsx";
import { NewAppointment } from "./pages/NewAppointment.tsx";
import { NotFoundPage } from "./pages/NotFound.tsx";
import { PastAppointments } from "./pages/PastAppointments.tsx";
import { SettingsPage } from "./pages/Settings.tsx";
import { TestingPage } from "./pages/TestPage.tsx";

export const appRouter = createBrowserRouter([
  {
    children: [
      {
        element: <Home />,
        index: true,
      },
      {
        element: <Appointments />,
        path: "/appointments",
      },
      {
        element: <DebugPage />,
        path: "/debug",
      },
      {
        element: <Me />,
        path: "/me",
      },
      {
        element: <PastAppointments />,
        path: "/past-visits",
      },
      {
        element: <TestingPage />,
        path: "/test",
      },
      {
        element: <AppointmentPage />,
        path: "/appointment/:id",
      },
      {
        element: <SettingsPage />,
        path: "/settings",
      },
      {
        element: <AvailabilitiesPage />,
        path: "/availabilities",
      },
      {
        element: <NewAppointment />,
        path: "/new-appointment/:scribe",
      },
      {
        element: <AppointmentConfirmation />,
        path: "/confirmation/:data/:intakeForm",
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
    element: <App config={appConfiguration} />,
    path: "/",
  },
]);

export default appRouter;

import { Appointment } from "@bonfhir/core/r4b";
import { Box, Space, Stack, Tabs, Text } from "@mantine/core";
import { ReactNode } from "react";
import { appointmentInFuture } from "../fhirUtils"; // isOngoing } from "../fhirUtils";
import { Button } from "./Button";

type Props = {
  appointment: Appointment | undefined;
  onEnterMeetingRoom?: (appointment: Appointment) => void;
  onViewDocumentation?: (appointment: Appointment) => void;
  notesGenerated?: boolean;
};

type Action = {
  component: ReactNode;
  text: string;
};

function getAction(
  {
    onViewDocumentation,
    onEnterMeetingRoom,
    notesGenerated,
  }: Omit<Props, "appointment">,
  appointment: Appointment,
): Action {
  const status = appointment?.status;
  const virtual = !!JSON.parse(appointment.patientInstruction ?? "")
    .patientLink;
  // const isWithinNow = isOngoing(appointment, new Date());
  const isAppointmentInfuture = appointmentInFuture(appointment);

  const res: Action = {
    component: null,
    text: "Nothing to show here.", // when appointment is in past, documentation is not generated, or appointment never happened. just something to display.
  };

  // If partcipants are connected and current date is between start and end date.
  if (status === "arrived") {
    //  && isWithinNow) {
    res.component = (
      <Button disabled size="lg">
        Recording in progress ...
      </Button>
    );
    res.text =
      "Please stay in the Video call. Your consultation will be recorded. You can keep this tab open.";
  }

  // This can be seen only if appointment end date is in future, and not started yet.
  // Status arrived has priority over this.
  if (
    isAppointmentInfuture &&
    status !== "cancelled" &&
    status !== "fulfilled" &&
    status !== "arrived"
  ) {
    res.text = virtual
      ? "Please turn on your camera and microphone. Make sure to end the call after the consultation"
      : "";
    res.component = (
      <Button
        onClick={() => {
          onEnterMeetingRoom && onEnterMeetingRoom(appointment);
        }}
        variant="filled"
        color="buttonPrimary"
        size="lg"
      >
        Enter meeting room
      </Button>
    );
  }

  // If appointment was recored but notes are still generating by AI.
  if (status === "fulfilled" && !notesGenerated) {
    res.text =
      "Your documentation is being generated and might take a few moments. You can return anytime to check on it.";
    res.component = (
      <Button size="lg" disabled>
        Generating notes ...
      </Button>
    );
  }

  // If appointment was recored, notes were generated But practitioner browsing other information than these notes.
  if (status === "fulfilled" && notesGenerated) {
    res.text = "Your documentation was successfully generated.";
    res.component = (
      <Button
        onClick={() => {
          onViewDocumentation && onViewDocumentation(appointment);
        }}
        variant="filled"
        color="buttonPrimary"
        size="lg"
      >
        View documentation
      </Button>
    );
  }

  return res;
}

export function ConsultationState({ appointment, ...props }: Props) {
  if (!appointment) {
    return;
  }

  const action = getAction(props, appointment);

  return (
    <Tabs.Panel value="consultation">
      <Stack align="center" justify="center" gap="lg">
        <Text ta="center" size="xl">
          {action.text}
        </Text>
        <Space h="sm" />
        <Box maw="25rem" w="100%">
          {action.component}
        </Box>
      </Stack>
    </Tabs.Panel>
  );
}

export const SleepingProblems = ({
  width = 41,
  height = 40,
  fill = "#FFF",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 900 681"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M361.5 69.7c-1.6.3-4.8 2-7 3.8-5.2 4.2-7.4 11.2-5.5 17.2 1.5 4.7 2.5 5.9 7.4 9.5 8.7 6.3 20.6 2.2 24.9-8.7 1.9-4.8 1.9-5.2.2-10-1.9-5.5-7.7-11.5-11-11.5-1.1 0-2.9-.2-4-.4s-3.3-.1-5 .1z"
        stroke="currentColor"
        fill={fill}
      />
      <path
        d="M429.6 69.7c-6.4 1-13.6 9.8-13.6 16.8 0 4.1 3.7 10.3 8.2 13.5 5.6 4.1 12.5 4.2 18.3.2 5.7-3.9 7.7-8.3 7.2-15.4-.9-10.7-8.8-16.7-20.1-15.1z"
        stroke="currentColor"
        fill={fill}
      />
      <path
        d="M497.5 69.9c-7.7 1.5-12.5 7.9-12.5 16.5 0 6.5 2.6 11 8.5 14.6 6.9 4.3 16.2 2 21.9-5.4 2.1-2.8 2.6-4.6 2.6-9.2 0-11.5-9.1-18.8-20.5-16.5z"
        stroke="currentColor"
        fill={fill}
      />
      <path
        d="M227.5 104.6c-13.8 2.9-18.9 4.6-31 10.5-11 5.3-18.3 10.6-28 20.4-14.8 14.7-23.6 30.1-29.9 51.7-3.5 12-3.1 42.1.8 55.3 2.7 9.3 7.9 21.5 11.9 28 7.3 11.9 21.5 27 32.5 34.3 30 20.2 68.2 24.9 100.7 12.4 4.5-1.7 17-7.6 20-9.5 16.1-9.9 32.8-28.3 40.5-44.7 1.2-2.5 2.7-5.6 3.4-7 1.2-2.4 2.5-6.6 6-19.1 1.2-4.1 1.6-10.2 1.6-22.8 0-17.3-1.1-25.6-4.6-33.9-.8-1.8-1.4-3.9-1.4-4.7 0-1.3-.5-2.5-5.1-11.5-3.8-7.5-4.2-8.2-10-16-8.2-10.9-22.4-24.4-28.9-27.3-.8-.4-2.8-1.6-4.5-2.7-1.6-1.1-3.9-2.4-5-2.9-9.9-4.4-13.9-6.1-15.1-6.1-.7 0-2.8-.6-4.6-1.4-9.9-4.3-35.5-5.8-49.3-3zm31.3 42.5c1.3.5 3.2.9 4.4.9 2.2 0 6.3 1.6 15.6 6.3 14.9 7.5 27.6 23.4 32.9 41.1.9 2.8 1.8 10.2 2 16.4.5 12.2-.6 17.9-6.2 31.2-1.3 3.3-6.2 10.7-9.8 14.9-3.6 4.2-12.5 12-15.2 13.2-1.1.5-3.8 1.9-6 3.2-10.9 6.1-26.4 8.5-40 6.4-8.6-1.4-14.3-3-17.5-4.8-.8-.5-2.4-1.3-3.6-1.8-7.6-3.3-16.6-10.9-22.8-19.1-6.6-8.9-8.1-11.8-11.8-22.5-2.9-8.5-2.9-28.3 0-37.6 7.7-24.9 28.1-43.4 52.7-47.8 3.3-.6 7.1-1.3 8.5-1.5 3.3-.5 13.9.5 16.8 1.5z"
        stroke="currentColor"
        fill={fill}
      />
      <path
        d="M36.4 195.9c-4 1-11.8 6.6-14.1 10-5.1 7.5-4.8-6-5.1 217.6-.2 235.1-1 213.4 7.9 222.5 13.1 13.6 32.8 11.7 43-4.2l3.4-5.2.5-26.9c.4-22.3.7-26.9 2-27.7 2.4-1.5 751.3-1.3 752.8.2.9.9 1.2 7.5 1.2 23.4 0 23.7.8 30.4 4.6 36.5 4.7 7.5 14.4 12.9 23.3 12.9 7.7 0 14.2-2.9 20-9 8.6-8.9 8.1 0 8.1-131.6-.1-69.4-.5-118.7-1-122.9-.6-3.9-1.3-9.3-1.6-12-.7-6.6-4.9-23.2-7.9-31.5-3.1-8.5-8.1-19.9-10.7-24.3-1.5-2.6-2.8-5-2.8-5.3 0-.6-10.8-16.2-14.8-21.4-5.3-6.7-26.1-27-31.3-30.5-2.4-1.7-4.5-3.3-4.7-3.8-.2-.4-.8-.7-1.4-.7-.5 0-2.4-1.1-4.1-2.4-1.8-1.3-5.4-3.6-8.2-5-2.7-1.5-5.7-3.1-6.5-3.6-7.3-4.5-23.5-10.5-38.5-14.3-19.2-4.9-10.3-4.7-185.3-4.7-159.2 0-163.9.1-169.9 2-5.6 1.7-8 2.7-12.3 5.2-.8.4-2.6 1.3-4 1.9-4.5 1.9-19.3 18-22.4 24.4-7.3 14.7-6.8 11-7.3 64.7-.4 43.5-.6 48.7-2.1 49.3-.8.3-62.1.6-136 .6-118.6-.1-134.8-.2-136.8-1.6l-2.3-1.5-.3-82.3c-.3-79.3-.4-82.3-2.2-85.2-3.1-4.7-6.8-8.5-11-11.2-3.1-2-5.5-2.6-11.5-2.9-4.2-.2-9 0-10.7.5zm422 91.7c1.4 1.4 1.6 9.4 1.6 72.8 0 54-.3 71.5-1.2 72.4-1.3 1.3-41.7 1.8-47.7.6-2-.4-3.8-1.8-5.1-3.9-1.9-3.2-2-4.9-2-69.6.1-72.3-.3-68.1 5.7-72.1 2.5-1.6 5.2-1.8 25-1.8 18.8 0 22.4.2 23.7 1.6zm268.1.2c23.1 5 34.9 9.8 51.2 20.9 10.2 7.1 28.4 26.1 31.6 33.3.4.8 1.6 2.8 2.6 4.5 1.1 1.6 2.9 5 4.1 7.5 1.1 2.5 2.8 5.8 3.5 7.3.8 1.6 1.5 3.5 1.5 4.2 0 .8.6 2.9 1.4 4.7 3.7 8.8 4.8 17.3 5.4 40l.5 22.7-2.4.6c-1.3.3-71.7.4-156.4.3l-154-.3-.3-72.5c-.1-39.9 0-73.1.3-73.8.7-1.8 202.1-1.2 211 .6zM349.4 435.2c1.2 4.6 4.3 13.9 5.6 16.8 6.2 14 20.5 27.4 35 32.8 12.8 4.8.5 4.5 226.8 4.9l211.2.3-.2 17.8-.3 17.7h-755l-.3-43.8c-.1-29.1.1-44.4.8-45.7 1.1-2 3.2-2 138.6-2 109.1 0 137.5.3 137.8 1.2z"
        stroke="currentColor"
        fill={fill}
      />
    </g>
  </svg>
);

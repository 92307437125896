import { Container, Space, Stack, Text, Title } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../ui-components/Button.tsx";
import classNames from "./Appointment.module.css";

export function AppointmentConfirmation() {
  const navigate = useNavigate();
  const { data, intakeForm } = useParams();

  return (
    <Container size="md" p="lg" className={classNames.root}>
      <Stack gap="xl" px="lg" py="xl" align="center" w="80%">
        {" "}
        <Title order={1}>Thank you for preparing the visit!</Title>
        <Text fz="xl" style={{ marginLeft: 20 }}>
          I am now finalizing preparations for the virtual visit with
          <Space h="lg" />
          {data}.
          <Space h="lg" />
          {intakeForm === "true"
            ? `As soon as the patient has confirmed the appointment, you can find it
          on the list of upcoming visits. The patient has received an e-mail for
          the virtual visit and the intake form`
            : "You can find the visit on your list of upcoming visits"}
          .
          <Space h="lg" />
          It was great working for you today!
        </Text>
        <Space h="lg" />
        <Button
          onClick={() => {
            navigate(`/appointments`);
          }}
          variant="filled"
          color="buttonPrimary"
          size="md"
          style={{ width: "40%" }}
        >
          Upcoming Visits
        </Button>
      </Stack>
    </Container>
  );
}

import { ReactNode, createContext, useState } from "react";

type LayoutThemeProps = {
  shouldShowNavbar: boolean;
  hideNavbar: () => void;
  showNavbar: () => void;
  rootBackground: string;
  changeRootBackground: (color: string) => void;
};

export const LayoutTheme = createContext<LayoutThemeProps>({
  shouldShowNavbar: true,
  hideNavbar: () => {},
  showNavbar: () => {},
  rootBackground: "backgroundPrimary",
  changeRootBackground: () => {},
});

type LayoutThemeProviderProps = {
  children: ReactNode;
};

export const LayoutThemeProvider = ({ children }: LayoutThemeProviderProps) => {
  const [shouldShowNavbar, setShouldShowNavbar] = useState(true);
  const [rootBackground, setRootBackground] = useState("backgroundPrimary");

  const hideNavbar = () => {
    setShouldShowNavbar(false);
  };
  const showNavbar = () => {
    setShouldShowNavbar(true);
  };

  const changeRootBackground = (color: string) => {
    setRootBackground(color);
  };

  return (
    <LayoutTheme.Provider
      value={{
        showNavbar,
        shouldShowNavbar,
        hideNavbar,
        rootBackground,
        changeRootBackground,
      }}
    >
      {children}
    </LayoutTheme.Provider>
  );
};

import {
  build,
  isReferenceOf,
  type Practitioner,
  type Reference,
  type Retrieved,
} from "@bonfhir/core/r4b";
import { useFhirRead } from "@bonfhir/query/r4b";
import { FhirQueryLoader } from "@bonfhir/react/r4b";
import { createContext, type ReactNode } from "react";

function examplePractitioner() {
  return build("Practitioner", {
    id: crypto.randomUUID(),
    name: [{ family: "Doe", given: ["John"] }],
  }) as Retrieved<Practitioner>;
}

export const CurrentProfile = createContext(examplePractitioner());

export type ProfileProviderProps = {
  children: ReactNode;
  profile: Reference;
};

export function ProfileProvider({ children, profile }: ProfileProviderProps) {
  const patientQuery = useFhirRead("Practitioner", profile, {
    query: { enabled: isReferenceOf(profile, "Practitioner") },
  });

  if (!isReferenceOf(profile, "Practitioner")) {
    <>
      Your current profile is not a practitioner but only practitioners may use
      this application.
    </>;
  }

  return (
    <FhirQueryLoader query={patientQuery}>
      {(patient) => (
        <CurrentProfile.Provider value={patient}>
          {children}
        </CurrentProfile.Provider>
      )}
    </FhirQueryLoader>
  );
}

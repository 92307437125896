import { Flex, Text } from "@mantine/core";

import { NavLink } from "react-router-dom";

import { useAuth } from "react-oidc-context";
import {
  CalendarCheck,
  CalendarMonth,
  Exit,
  MedicalClipboard,
  Microphone,
  SliderSettings,
} from "../icons";
import classes from "./NavBar.module.css";

type Link = {
  href?: string;
  onClick?: () => void;
  label: string;
  red?: boolean;
  icon?: JSX.Element;
};

export function NavBar() {
  const auth = useAuth();

  const links: Array<Link> = [
    {
      href: "/new-appointment/scribe",
      label: "Scribe a visit",
      icon: <Microphone height={15} width={15} />,
    },
    {
      href: "/new-appointment/prepare",
      label: "Prepare a visit",
      icon: <MedicalClipboard height={15} width={15} />,
    },
    {
      href: "/appointments",
      label: "Upcoming visits",
      icon: <CalendarMonth height={15} width={15} />,
    },
    {
      href: "/past-visits",
      label: "Past visits",
      icon: <CalendarCheck height={15} width={15} />,
    },
    {
      href: "/settings",
      label: "Settings",
      icon: <SliderSettings height={15} width={15} />,
    },
    // {
    //   href: "/availabilities",
    //   label: "Availabilities",
    //   icon: <CalendarPlus />
    // },
    {
      onClick: () => {
        auth.removeUser();
        auth.signoutRedirect();
      },
      label: "Sign out",
      icon: <Exit height={15} width={15} />,
      // red: true,
    },
  ];

  return (
    <Flex direction="column" py={40} px={20} gap="md">
      {Object.values(links).map((link) => {
        return link.href ? (
          <NavLink
            className={({ isActive }) => {
              if (link.red) {
                return classes.navElementRed;
              }

              return isActive ? classes.activeElement : classes.navElement;
            }}
            key={link.label}
            to={link.href || "/"}
          >
            <Text className={link.red ? classes.labelRed : classes.label}>
              {link.icon} {link.label}
            </Text>
          </NavLink>
        ) : (
          <div
            key={link.label}
            className={classes.navElementRed}
            onClick={() => (link.onClick ? link.onClick() : "")}
          >
            <Text className={link.red ? classes.labelRed : classes.label}>
              {link.icon} {link.label}
            </Text>
          </div>
        );
      })}
    </Flex>
  );
}

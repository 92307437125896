import {
  ButtonProps,
  Button as MantineButton,
  PolymorphicComponentProps,
} from "@mantine/core";
import { forwardRef, type ComponentProps } from "react";
import classes from "./Button.module.css";

type Props = ComponentProps<typeof MantineButton> &
  PolymorphicComponentProps<"button", ButtonProps> & {
    size?: "sm" | "md" | "lg" | "xl";
    children: string | JSX.Element | (string | JSX.Element)[];
  };

export function Button({ size = "md", children, ...props }: Props) {
  const buttonSize = classes[size];
  const disabled = props.disabled ? classes.disabled : "";

  return (
    <MantineButton className={`${buttonSize} ${disabled}`} {...props}>
      {children}
    </MantineButton>
  );
}

export const RefButton = forwardRef<HTMLButtonElement, Props>(
  ({ size = "md", children, ...props }, ref) => {
    const buttonSize = classes[size];
    const disabled = props.disabled ? classes.disabled : "";

    return (
      <MantineButton
        ref={ref}
        className={`${buttonSize} ${disabled}`}
        {...props}
      >
        {children}
      </MantineButton>
    );
  },
);

import { Box, Flex, Stack, Text, Title } from "@mantine/core";
import { Button, RefButton } from "./Button";

import { Appointment } from "@bonfhir/core/r4b";
import { FhirValue } from "@bonfhir/react/r4b";
import { RefObject, useEffect, useState } from "react";
import { SERVER_URL } from "../app-configuration";
import {
  // appointmentInFuture,
  isOngoing,
  prettyAppointmentDate,
} from "../fhirUtils";
import classNames from "./AppointmentBanner.module.css";

type Props = {
  appointment: Appointment | undefined;
  onEnterMeetingRoom?: (appointment: Appointment) => void;
  onCancelAppointment?: (appointment: Appointment) => void;
  onViewDocumentation?: (appointment: Appointment) => void;
  onSaveButtonReference?: RefObject<HTMLButtonElement>;
  notesGenerated?: boolean;
  documentationViewed?: boolean; // When practitioner has not Documentation tab opened.
  notesSubmitted?: boolean;
  saveAndSubmit: () => void;
  isOnline: boolean;
  token: string | undefined;
  inCall: boolean;
};

function getAppointmentActions(
  props: Omit<Props, "appointment" | "saveAndSubmit">,
  appointment: Appointment,
  saveAndSubmit: () => void,
  endingCall: boolean,
  setEndingCall: (ending: boolean) => void,
) {
  const {
    notesGenerated,
    documentationViewed,
    onSaveButtonReference,
    onEnterMeetingRoom,
    onCancelAppointment,
    onViewDocumentation,
    notesSubmitted,
    isOnline,
    token,
    inCall,
  } = props;
  const status = appointment?.status;
  const isWithinNow = isOngoing(appointment); //, new Date());
  const isAppointmentInfuture = true; // appointmentInFuture(appointment);

  // If partcipants are connected and current date is between start and end date.
  const doctor = appointment.participant.find(
    (p) => p?.actor?.type === "Practitioner",
  );

  // If appointment was recored but notes are still generating by AI.
  if ((status === "fulfilled" && !notesGenerated) || endingCall) {
    return (
      <>
        <Button size="lg" disabled>
          Generating notes...
        </Button>
      </>
    );
  }

  if (
    (status === "arrived" && isWithinNow && doctor?.status === "accepted") ||
    inCall === true
  ) {
    return (
      <>
        <Button color="buttonActive" disabled size="lg">
          Recording in progress ...
        </Button>
        {!isOnline && (
          <Button
            size="lg"
            onClick={() => {
              setEndingCall(true);
              fetch(`${SERVER_URL}/appointment/end`, {
                method: "POST",
                headers: {
                  Authorization: `bearer ${token}`,
                  "content-type": "application/json",
                },
                body: JSON.stringify({ appointmentId: appointment.id }),
              });
            }}
            color="buttonAccent"
          >
            End Appointment
          </Button>
        )}
      </>
    );
  }

  // This can be seen only if appointment end date is in future, and not started yet.
  // Status arrived has priority over this.
  if (
    isAppointmentInfuture &&
    status !== "cancelled" &&
    status !== "fulfilled"
  ) {
    return (
      <>
        <Button
          onClick={() => {
            onEnterMeetingRoom && onEnterMeetingRoom(appointment);
          }}
          variant="filled"
          color="buttonPrimary"
          size="lg"
        >
          {isOnline ? "Enter meeting room" : "Start scribe"}
        </Button>
        <Button
          onClick={() => {
            onCancelAppointment && onCancelAppointment(appointment);
          }}
          variant="outline"
          size="lg"
          color="buttonAccent"
        >
          Cancel appointment
        </Button>
      </>
    );
  }

  // If appointment was recored, notes were generated But practitioner browsing other information than these notes.
  if (status === "fulfilled" && notesGenerated && !documentationViewed) {
    return (
      <>
        <Button
          onClick={() => {
            onViewDocumentation && onViewDocumentation(appointment);
          }}
          variant="filled"
          color="buttonPrimary"
          size="lg"
        >
          View documentation
        </Button>
      </>
    );
  }

  // If appointment was recored, notes were generated and practitioner viewing documentation right now.
  if (
    status === "fulfilled" &&
    notesGenerated &&
    documentationViewed &&
    !notesSubmitted
  ) {
    return (
      <>
        <RefButton
          ref={onSaveButtonReference}
          onClick={() => saveAndSubmit()}
          variant="filled"
          color="buttonAccent"
          size="lg"
        >
          Save
        </RefButton>
      </>
    );
  }

  // This should be only if
  // appointment was recored, notes were generated and submitted.
  // So practitioneer will only see the date when this appointment took place.

  return <></>;
}

export function AppointmentBanner({
  appointment,
  saveAndSubmit,
  ...actions
}: Props) {
  const [endingCall, setEndingCall] = useState(false);
  useEffect(() => {
    if (appointment?.status === "fulfilled" && actions.notesGenerated) {
      setEndingCall(false);
    }
  }, [appointment?.status, actions.notesGenerated]);

  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    if (
      (appointment?.status === "fulfilled" && !actions.notesGenerated) ||
      endingCall
    ) {
      interval = setInterval(() => {
        setCountdown((prevCount) => (prevCount > 0 ? prevCount - 1 : 30)); // Reset to 30 when it reaches 0
      }, 1000);
    } else {
      setCountdown(30); // Reset countdown when not in loading state
    }

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [appointment?.status, endingCall, actions.notesGenerated]);

  if (!appointment) {
    return null;
  }

  const patientReference = appointment.participant.find((actor) =>
    actor.actor?.reference?.startsWith("Patient"),
  );

  const patient = patientReference?.actor;
  const date = prettyAppointmentDate(appointment);
  // const isFulfilled = appointment.status === "fulfilled";

  return (
    <Box
      w="100%"
      className={classNames.container}
      bg="buttonActive"
      // {
      // isFulfilled || endingCall ? "backgroundPostCall" : "backgroundSecondary"
      // }
    >
      <Flex
        gap="lg"
        className={classNames.contentContainer}
        align="center"
        justify="space-between"
      >
        <Stack gap="xl">
          {appointment?.description && (
            <Text c="textSecondary" size="lg">
              {appointment.comment?.split(" Years old, ")[1]}
            </Text>
            // <FhirValue
            //   rendererProps={{
            //     text: {
            //       size: "24px",
            //       fw: "bold",
            //     },
            //   }}
            //   type="string"
            //   value={appointment.description}
            // />
          )}
          {patient && (
            <FhirValue
              rendererProps={{
                text: {
                  size: "36px",
                  fw: "bold",
                },
              }}
              type="Reference"
              value={patient}
            />
          )}
          <Text c="textSecondary" size="md">
            {appointment.comment?.split(",")[0]}
          </Text>
        </Stack>
        <Stack className={classNames.actionContainer} bg="backgroundPrimary">
          {(appointment.status === "fulfilled" && !actions.notesGenerated) ||
          endingCall ? (
            <Title ta="center" order={3}>
              {countdown}
            </Title>
          ) : (
            <Title order={3}>{date}</Title>
          )}
          {getAppointmentActions(
            actions,
            appointment,
            saveAndSubmit,
            endingCall,
            setEndingCall,
          )}
        </Stack>
      </Flex>
    </Box>
  );
}

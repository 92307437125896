import {
  AppointmentParticipant,
  CarePlan,
  Communication,
  Composition,
  DiagnosticReport as DiagnosticReportRB4,
  Retrieved,
  reference,
} from "@bonfhir/core/r4b";
import {
  useFhirPatchMutation,
  useFhirSearchOne,
  useFhirTransactionMutation,
} from "@bonfhir/query/r4b";
import {
  Container,
  Flex,
  Loader,
  Modal,
  Space,
  Stack,
  Tabs,
  Text,
  Title,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LayoutTheme } from "../components/LayoutTheme";
import { AppointmentBanner } from "../ui-components/AppointmentBanner";
import { Button } from "../ui-components/Button";

import { ConsultationState } from "../ui-components/ConsultationState";
import { Documentation } from "../ui-components/Documentation";
import { PatientIntakeTab } from "../ui-components/PatientIntakeTab";
import { TabsList } from "../ui-components/TabsList";
import classNames from "./Appointment.module.css";

import { useAuth } from "react-oidc-context";
import { SERVER_URL } from "../app-configuration";
import { CurrentProfile } from "../integrations/bonfhir";
import { VideoCall } from "../ui-components/VideoCall";

export type DiagnosticReport = DiagnosticReportRB4 & {
  note?: [
    {
      text: string;
    },
  ];
};

export function AppointmentPage() {
  // Initial hooks
  const { hideNavbar, changeRootBackground, showNavbar } =
    useContext(LayoutTheme);
  const params = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const profile = useContext(CurrentProfile);

  // State
  const [activeTab, setActiveTab] = useState<string | null>("patientIntake");
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [notesSubmitted, setNotesSubmitted] = useState(false);
  const [notesGenerated, setNotesGenerated] = useState(false);

  const [carePlan, setCarePlan] = useState<Retrieved<CarePlan>>();
  const [doctorsLetter, setDoctorsLetter] =
    useState<Retrieved<Communication>>();
  const [notes, setNotes] = useState<DiagnosticReport>();
  const [composition, setComposition] = useState<Composition>();

  const [visitNotes, setVisitNotes] = useState("");
  const [doctorLetter, setDoctorLetter] = useState("");
  const [treatmentPlan, setTreatmentPlan] = useState("");
  const [coding, setCoding] = useState("");

  const [inCall, setInCall] = useState(false);

  // References
  // Used to trigger Save event for the Banner component without having state
  const saveDocumnetationBtn = useRef<HTMLButtonElement>(null);
  const bannerDocumentationBtn = useRef<HTMLButtonElement>(null);
  // TODO: Set dynamically depending on appointment stage
  const DOCUMENTATION_REFETCH_INTERVAL_MS = 4 * 1000;

  // References used to not duplicate state management inside the Banner component, and keep everything inside the Documentation component. When button in banner will be clicked the will be triggered event in documentation component save button.
  useEffect(() => {
    const triggerSaveBtnClick = () => {
      if (saveDocumnetationBtn.current) {
        saveDocumnetationBtn.current.click();
      }
    };

    const bannerBtn = bannerDocumentationBtn.current;
    if (bannerBtn) {
      bannerBtn.addEventListener("click", triggerSaveBtnClick);
    }

    return () => {
      if (bannerBtn) {
        bannerBtn.removeEventListener("click", triggerSaveBtnClick);
      }
    };
  }, []);

  // Theme settings
  useEffect(() => {
    hideNavbar();
    changeRootBackground("backgroundGrey");
  }, [hideNavbar, changeRootBackground]);

  // FHIR Queries
  // Refetch when
  const appointmentRequest = useFhirSearchOne(
    "Appointment",
    (query) => {
      return query._id(params.id)._include("Appointment", "patient");
    },
    {
      query: {
        refetchInterval: DOCUMENTATION_REFETCH_INTERVAL_MS,
      },
    },
  );

  const appointment = appointmentRequest.data;
  const { providerLink, patientLink } = JSON.parse(
    appointment?.patientInstruction ?? "{}",
  );
  const isOnline = !!patientLink;

  const encounterRequest = useFhirSearchOne(
    "Encounter",
    (query) => {
      return query.appointment(reference(appointment));
    },
    {
      query: {
        refetchInterval: DOCUMENTATION_REFETCH_INTERVAL_MS, // we need to pool these resources each minute or so, to check if the documentation is generated.
      },
    },
  );

  const encounter = encounterRequest.data;

  // Base64 content in DocumentReference as an AI generated notes which we can retrieve using encounter object

  // const documentReferenceRequest = useFhirSearch(
  //   "DocumentReference",
  //   (query) => {
  //     return query.encounter(reference(encounter)).patient(reference(patient));
  //   },
  //   // {
  //   //   query: {
  //   //     // Set auto refetch only when encounter exists which means that appointment is finished
  //   //     enabled: !!encounter,

  //   //     refetchInterval: DOCUMENTATION_REFETCH_INTERVAL_MS, // we need to pool these resources each minute or so, to check if the documentation is generated.
  //   //   },
  //   // },
  // );

  //  Base64 content in DiagnosticReport as an AI Generated patient summarry which we can retrieve using encounter object
  //  List of codes that are located in DiagnosticReport field: `conclusionCode`
  const diagnosticReportRequest = useFhirSearchOne(
    "DiagnosticReport",
    (query) => {
      return query.encounter(reference(encounter));
    },
    {
      query: {
        refetchInterval: DOCUMENTATION_REFETCH_INTERVAL_MS, // we need to pool these resources each minute or so, to check if the documentation is generated.
      },
    },
  );

  // String patient education that is located in resource Communication and can be retrieved using encounter id and it's represents the treatment plan for the patient
  const communicationRequest = useFhirSearchOne(
    "Communication",
    (query) => {
      return query.encounter(reference(encounter));
    },
    {
      query: {
        refetchInterval: DOCUMENTATION_REFETCH_INTERVAL_MS, // we need to pool these resources each minute or so, to check if the documentation is generated.
      },
    },
  );

  const carePlanRequest = useFhirSearchOne(
    "CarePlan",
    (query) => {
      return query.encounter(reference(encounter));
    },
    {
      query: {
        refetchInterval: DOCUMENTATION_REFETCH_INTERVAL_MS, // we need to pool these resources each minute or so, to check if the documentation is generated.
      },
    },
  );

  const compositionRequest = useFhirSearchOne(
    "Composition",
    (query) => {
      return query.encounter(reference(encounter));
    },
    {
      query: {
        refetchInterval: DOCUMENTATION_REFETCH_INTERVAL_MS, // we need to pool these resources each minute or so, to check if the documentation is generated.
      },
    },
  );

  useEffect(() => {
    setDoctorsLetter(communicationRequest?.data);
    setCarePlan(carePlanRequest?.data);
    setNotes(diagnosticReportRequest?.data);
    setComposition(compositionRequest?.data);
    if (
      communicationRequest?.data?.id &&
      carePlanRequest?.data?.id &&
      diagnosticReportRequest?.data?.id &&
      compositionRequest?.data?.id
    ) {
      setNotesGenerated(true);
    }
  }, [
    carePlanRequest.data,
    communicationRequest.data,
    diagnosticReportRequest.data,
    compositionRequest.data,
  ]);

  // Mutation Transaction for the Medplum generated notes
  const saveGeneratedNotesTransaction = useFhirTransactionMutation();
  const patchAppointmentMutation = useFhirPatchMutation("Appointment");

  // Check if notes are generated and or submited
  useEffect(() => {
    // check if all documentation is generated.r
    if (notes || carePlan || doctorsLetter || composition) {
      setNotesGenerated(true);
    }

    // if at least one document exists we know that documentation was generated
    // if status is not final at least in one document then it's not counted as submitted
    if (
      notes?.status === "final" ||
      carePlan?.status === "active" ||
      doctorsLetter?.status === "completed" ||
      composition?.status === "final"
    ) {
      setNotesSubmitted(true);
    }
    // setNotesGenerated(true);
  }, [notes, carePlan, doctorsLetter, composition]);

  if (!appointment && !appointmentRequest.isFetching) {
    return <div>Appointment not found</div>;
  } else if (!appointment) {
    return <Loader />;
  }

  const onJoinMeeting = () => {
    // set Practitioner participant status
    const paticipants = appointment.participant.map((p) =>
      p?.actor?.type === "Practitioner" ? { ...p, status: "accepted" } : p,
    ) as unknown as AppointmentParticipant[];
    patchAppointmentMutation.mutate({
      id: appointment.id,
      body: (patch) => patch.replace("/participant", paticipants),
    });
    setInCall(true);
    // window.open(appointment.patientInstruction, "_blank");
  };

  const openCancelModal = () => {
    setCancelModalOpen(true);
  };

  const onDocumentationOpen = () => {
    setActiveTab("documentation");
  };

  const cancelAppointment = () => {
    const token = auth.user?.access_token;
    fetch(`${SERVER_URL}/provider-cancel-appointment/${appointment.id}`, {
      method: "GET",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then(() => setCancelModalOpen(false))
      .catch((e) => console.log(e));
  };

  const onSaveAndSubmit = (
    visitNotes: string,
    doctorLetter: string,
    treatmentPlan: string,
    coding: string,
    // TODO:: Save coding as well
  ) => {
    saveGeneratedNotesTransaction.mutate((transaction) => {
      // We assume that even if notes generation was failed we still will have 3 these resources but with status failed.
      if (!notes || !carePlan || !doctorsLetter || !composition) {
        return;
      }
      // Save visit notes
      // const documentReference =
      // carePlanRequest.data as unknown as DocumentReference;
      // documentReference.docStatus = "final";
      // documentReference.content = [
      //   {
      //     attachment: {
      //       data: btoa(visitNotes),
      //     },
      //   },
      // ];
      // transaction.update(documentReference);

      // Save diagnostic report
      const diagnosticReport = diagnosticReportRequest.data as DiagnosticReport;
      diagnosticReport.status = "final";
      diagnosticReport.conclusion = visitNotes;
      // diagnosticReport.presentedForm = [
      //   {
      //     data: btoa(doctorLetter),
      //   },
      // ];
      transaction.update(diagnosticReport);

      // TODO:: Save codes

      // Save treatment plan
      const commnication =
        communicationRequest.data as unknown as Communication;
      commnication.status = "completed";
      if (commnication.payload)
        commnication.payload[0].contentString = doctorLetter;
      transaction.update(commnication);

      // Save care plan
      const carePlanUpdate = carePlanRequest.data as unknown as CarePlan;
      carePlanUpdate.status = "active";
      carePlanUpdate.description = treatmentPlan;
      transaction.update(carePlanUpdate);

      // Save coding support
      const compositionUpdate = compositionRequest.data as Composition;
      compositionUpdate.title = coding;
      transaction.update(compositionUpdate);

      showNavbar();
      navigate("/appointments");
    });
  };
  const name = profile.name && profile.name[0];
  const userName = `${name?.given && name.given[0]} ${name?.family}`;

  return (
    <Container fluid className={classNames.root}>
      <Stack gap="sm" align="center" w="100%">
        <Flex justify="start" w="100%">
          <Button
            size="md"
            leftSection={<IconArrowLeft size={20} />}
            variant="subtle"
            color="buttonPrimary"
            onClick={() => {
              navigate(
                appointment.status === "fulfilled"
                  ? "/past-visits"
                  : "/appointments",
              );
              showNavbar();
              changeRootBackground("backgroundPrimary");
            }}
          >
            Back
          </Button>
        </Flex>
        <Stack className={classNames.contentContainer} bg="backgroundPrimary">
          {inCall && (
            <VideoCall
              isOnline={isOnline}
              url={providerLink}
              userName={userName}
              onLeave={() => setInCall(false)}
            />
          )}
          {(!isOnline || !inCall) && (
            <AppointmentBanner
              onEnterMeetingRoom={onJoinMeeting}
              onCancelAppointment={openCancelModal}
              onViewDocumentation={onDocumentationOpen}
              onSaveButtonReference={bannerDocumentationBtn}
              saveAndSubmit={() =>
                onSaveAndSubmit(visitNotes, doctorLetter, treatmentPlan, coding)
              }
              appointment={appointment}
              documentationViewed={activeTab === "documentation"}
              notesSubmitted={notesSubmitted}
              notesGenerated={notesGenerated}
              isOnline={isOnline}
              token={auth.user?.access_token}
              inCall={inCall}
            />
          )}
          <Space h="lg" />

          <Flex justify="space-between" gap="md" w="100%">
            <Stack flex={1}>
              <Tabs value={activeTab} onChange={setActiveTab}>
                <TabsList
                  // appointment={appointment}
                  activeTab={activeTab}
                  documentationAvailable={notesGenerated}
                />
                <Space h="xl" />

                <Stack p="xl">
                  <Tabs.Panel value="patientIntake">
                    <PatientIntakeTab appointment={appointment} />
                  </Tabs.Panel>
                  <ConsultationState
                    onEnterMeetingRoom={onJoinMeeting}
                    appointment={appointment}
                    onViewDocumentation={onDocumentationOpen}
                    notesGenerated={notesGenerated}
                  />
                  <Documentation
                    onSaveAndSend={onSaveAndSubmit}
                    finalized={notesSubmitted}
                    generatedNotes={notes}
                    patientEducation={doctorsLetter}
                    carePlan={carePlan}
                    saveBtnRef={saveDocumnetationBtn}
                    visitNotes={visitNotes}
                    setVisitNotes={setVisitNotes}
                    doctorLetter={doctorLetter}
                    setDoctorLetter={setDoctorLetter}
                    treatmentPlan={treatmentPlan}
                    setTreatmentPlan={setTreatmentPlan}
                    composition={composition}
                    coding={coding}
                    setCoding={setCoding}
                  />
                </Stack>
              </Tabs>
            </Stack>
            {/* <Box flex={0} miw="35%">
              {patient && <PatientCard patient={patient} />}
            </Box> */}
          </Flex>
        </Stack>
      </Stack>
      <Modal
        opened={cancelModalOpen}
        onClose={() => {
          setCancelModalOpen(false);
        }}
        size="lg"
        p="xl"
      >
        <Flex justify="center">
          <Stack w="70%" p="lg" gap="lg" align="center" justify="space-between">
            <Title ta="center" order={2}>
              Do you want to cancel the appointment?
            </Title>

            <Button
              onClick={cancelAppointment}
              variant="filled"
              color="buttonAccent"
              size="lg"
            >
              Cancel appointment
            </Button>

            <Button
              onClick={() => {
                setCancelModalOpen(false);
              }}
              variant="outline"
              size="lg"
              color="buttonAccent"
            >
              Return to appointment
            </Button>

            <Text c="textAlert">Technical problems? Get in touch with us!</Text>
          </Stack>
        </Flex>
      </Modal>
    </Container>
  );
}

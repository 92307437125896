export const JointPain = ({
  width = 19,
  height = 40,
  stroke = "#495057",
  strokeWidth = 2,
  ...props
}) => (
  <svg viewBox="0 0 19 40" width={width} height={height} {...props}>
    <path
      d="M6.99996 14.1667C8.62279 13.6257 10.3771 13.6257 12 14.1667M6.99996 25.837C8.62279 26.378 10.3771 26.378 12 25.837M17.8333 13.7463C17.8333 12.1845 17.0963 10.7947 15.951 9.90569C15.4426 9.51112 15.0555 8.94429 15.0555 8.30081V3.33332C15.0555 2.41286 14.3093 1.66666 13.3888 1.66666H5.61108C4.69059 1.66666 3.94441 2.41286 3.94441 3.33332V8.30077C3.94441 8.94426 3.55728 9.51109 3.04896 9.90566C1.90364 10.7947 1.16663 12.1845 1.16663 13.7463C1.16663 16.4302 3.34303 18.606 6.02774 18.606C6.77846 18.606 7.48946 18.4358 8.12429 18.132C8.96629 17.729 10.0336 17.729 10.8756 18.132C11.5105 18.4358 12.2215 18.606 12.9721 18.606C15.657 18.606 17.8333 16.4302 17.8333 13.7463ZM17.8333 26.2537C17.8333 27.8155 17.0963 29.2052 15.951 30.0943C15.4426 30.4888 15.0555 31.0557 15.0555 31.6992V36.6667C15.0555 37.5872 14.3093 38.3333 13.3888 38.3333H5.61108C4.69059 38.3333 3.94441 37.5872 3.94441 36.6667V31.6992C3.94441 31.0557 3.55728 30.4888 3.04896 30.0943C1.90364 29.2053 1.16663 27.8155 1.16663 26.2537C1.16663 23.5698 3.34303 21.394 6.02774 21.394C6.77846 21.394 7.48946 21.5642 8.12429 21.868C8.96629 22.271 10.0336 22.271 10.8756 21.868C11.5105 21.5642 12.2215 21.394 12.9721 21.394C15.657 21.394 17.8333 23.5698 17.8333 26.2537Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
);

import { Patient, Practitioner, Retrieved } from "@bonfhir/core/r4b";
import { FhirValue } from "@bonfhir/react/r4b";
import { Flex } from "@mantine/core";
import classNames from "./ProfileAvatar.module.css";
export type ProfileAvatarProps = {
  profile: Retrieved<Patient | Practitioner>;
};

export function ProfileAvatar(props: ProfileAvatarProps) {
  // const { profile } = props;

  // const profilePhoto =
  //   profile.photo && profile.photo[0] ? profile.photo[0] : undefined;

  return (
    <Flex align="center" direction="row" gap="md">
      <FhirValue
        options={{ style: "full" }}
        type="HumanName"
        value={props.profile.name}
        rendererProps={{
          text: {
            f: "md",
            c: "textLink",
          },
        }}
        className={classNames.borderLeft}
      />
      {/* <Avatar
        alt={profilePhoto?.title}
        radius="xl"
        size="lg"
        src={profilePhoto?.url}
      > */}
      {/* <FhirValue
        options={{ style: "shorter" }}
        type="HumanName"
        value={profile.name}
      /> */}
      {/* </Avatar> */}
    </Flex>
  );
}

export const CalendarCheck = ({
  width = 24,
  height = 24,
  stroke = "#495057",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={stroke}
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1607_7250)">
      <path
        d="M6.15001 1C6.15001 0.585786 5.81422 0.25 5.40001 0.25C4.98579 0.25 4.65001 0.585786 4.65001 1H6.15001ZM4.65001 5.39999C4.65001 5.81421 4.98579 6.14999 5.40001 6.14999C5.81422 6.14999 6.15001 5.81421 6.15001 5.39999H4.65001ZM19.35 1C19.35 0.585786 19.0142 0.25 18.6 0.25C18.1857 0.25 17.85 0.585786 17.85 1H19.35ZM17.85 5.39999C17.85 5.81421 18.1857 6.14999 18.6 6.14999C19.0142 6.14999 19.35 5.81421 19.35 5.39999H17.85ZM1 9.2674L0.25 9.2674L0.25 10.7674L1 10.7674L1 9.2674ZM22.0406 10.7674C22.4548 10.7674 22.7906 10.4316 22.7906 10.0174C22.7906 9.60319 22.4548 9.2674 22.0406 9.2674L22.0406 10.7674ZM9.50356 16.1609C9.18907 15.8913 8.71559 15.9278 8.44602 16.2423C8.17646 16.5568 8.21288 17.0302 8.52737 17.2998L9.50356 16.1609ZM11.1195 18.5338L10.6314 19.1033C10.9447 19.3718 11.4161 19.3368 11.6863 19.025L11.1195 18.5338ZM15.5938 14.5163C15.8651 14.2033 15.8313 13.7296 15.5183 13.4584C15.2052 13.1871 14.7316 13.2209 14.4603 13.5339L15.5938 14.5163ZM2.00193 3.28746H21.998V1.78746H2.00193V3.28746ZM22.25 3.53939V21.9981H23.75V3.53939H22.25ZM21.998 22.25H2.00193V23.75H21.998V22.25ZM1.75 21.9981V3.53939H0.25V21.9981H1.75ZM2.00193 22.25C1.86279 22.25 1.75 22.1372 1.75 21.9981H0.25C0.25 22.9656 1.03437 23.75 2.00193 23.75V22.25ZM22.25 21.9981C22.25 22.1372 22.1372 22.25 21.998 22.25V23.75C22.9656 23.75 23.75 22.9656 23.75 21.9981H22.25ZM21.998 3.28746C22.1372 3.28746 22.25 3.40025 22.25 3.53939H23.75C23.75 2.57183 22.9656 1.78746 21.998 1.78746V3.28746ZM2.00193 1.78746C1.03437 1.78746 0.25 2.57183 0.25 3.53939H1.75C1.75 3.40025 1.8628 3.28746 2.00193 3.28746V1.78746ZM4.65001 1V5.39999H6.15001V1H4.65001ZM17.85 1V5.39999H19.35V1H17.85ZM1 10.7674L22.0406 10.7674L22.0406 9.2674L1 9.2674L1 10.7674ZM8.52737 17.2998L10.6314 19.1033L11.6076 17.9644L9.50356 16.1609L8.52737 17.2998ZM11.6863 19.025L15.5938 14.5163L14.4603 13.5339L10.5528 18.0426L11.6863 19.025Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1607_7250">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

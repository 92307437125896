import { DailyProvider, useCallFrame } from "@daily-co/daily-react";
import { MutableRefObject, useEffect, useRef } from "react";

export const VideoCall = ({
  url,
  userName,
  onLeave,
  isOnline,
}: {
  url: string;
  userName: string;
  onLeave: () => void;
  isOnline: boolean;
}) => {
  const callRef = useRef(null);
  // TODO: make this responsive
  const callFrame = useCallFrame({
    parentElRef: callRef as unknown as MutableRefObject<HTMLElement>,
    options: {
      showLeaveButton: true,
      iframeStyle: {
        opacity: isOnline ? "1" : "0",
        position: "sticky",
        top: "0",
        minHeight: isOnline ? "800px" : "0px",
        minWidth: isOnline ? "1200px" : "0px",
        height: isOnline ? "100%" : "1px",
        width: isOnline ? "100%" : "1px",
        zIndex: "10",
      },
    },
  });
  useEffect(() => {
    if (callFrame) {
      callFrame.join({ url, userName });

      const handleLeftMeeting = () => {
        onLeave();
      };

      callFrame.on("left-meeting", handleLeftMeeting);

      return () => {
        callFrame.off("left-meeting", handleLeftMeeting);
        callFrame.destroy();
      };
    }
  }, [callFrame]);
  return (
    <DailyProvider callObject={callFrame}>
      <div ref={callRef} />
    </DailyProvider>
  );
};

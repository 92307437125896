import { Tabs } from "@mantine/core";

// import { Appointment, WithResolvableReferences } from "@bonfhir/core/r4b";
// import { isOngoing } from "../fhirUtils";
import classNames from "./TabsList.module.css";

type Props = {
  activeTab: string | null;
  // appointment: WithResolvableReferences<Appointment>;
  documentationAvailable: boolean;
};

export function TabsList({
  activeTab,
  // appointment,
  documentationAvailable = false,
}: Props) {
  // const appointmentOngoing = isOngoing(appointment); //, new Date());
  return (
    <Tabs.List grow className={classNames.tabsList}>
      <Tabs.Tab
        className={
          activeTab === "patientIntake"
            ? classNames.tabElementActive
            : classNames.tabElement
        }
        value="patientIntake"
      >
        Patient intake
      </Tabs.Tab>
      {/* <Flex align="center">
        <Tabs.Tab
          className={
            activeTab === "consultation"
              ? classNames.tabElementActive
              : classNames.tabElement
          }
          value="consultation"
          rightSection={
            appointmentOngoing && (
              <div className={classNames.ongoingIndicatorContainer}>
                <div className={classNames.ongoingIndicator}></div>
              </div>
            )
          }
        >
          Consultation
        </Tabs.Tab>
      </Flex> */}

      <Tabs.Tab
        className={
          activeTab === "documentation"
            ? classNames.tabElementActive
            : classNames.tabElement
        }
        value="documentation"
        disabled={!documentationAvailable}
      >
        Documentation
      </Tabs.Tab>
    </Tabs.List>
  );
}

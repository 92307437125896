import { colorsTuple, createTheme } from "@mantine/core";

export const CascadeTheme = createTheme({
  fontFamily: "Lexend",
  colors: {
    // buttons
    buttonActive: colorsTuple("#dbf1ef"),
    buttonAccent: colorsTuple("#7E4DD2"),
    buttonPrimary: colorsTuple("#44b7b0"),
    buttonDisabled: colorsTuple("#5D5F66"),
    buttonSecondary: colorsTuple("#e0f0f0"),
    buttonAction: colorsTuple("#44b7b0"),
    // typography
    textPrimary: colorsTuple("#44b7b0"),
    textSecondary: colorsTuple("#4B5462"),
    textInactive: colorsTuple("#808080"),
    textAlert: colorsTuple("#D81E5C"),
    textLink: colorsTuple("#000000"),
    // background
    backgroundPrimary: colorsTuple("#ffffff"),
    backgroundSecondary: colorsTuple("#44b7b0"),
    backgroundActive: colorsTuple("#F9FAFB"),
    backgroundPostCall: colorsTuple("#44b7b0"),
    backgroundGrey: colorsTuple("#F3F4F6"),
    // border
    borderPrimary: colorsTuple("#D1D5DB"),
    borderActive: colorsTuple("#7ACFFA"),
    // indicator
    indicatorPrimary: colorsTuple("#D81E5C"),
    indicatorBackground: colorsTuple("#FDD2E6"),
  },
});

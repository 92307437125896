export const BloodPressure = ({
  width = 300,
  height = 302,
  stroke = "currentColor",
  fill = "#FFF",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 300 302"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M22 13.3c-4.7 1.5-11.6 8.6-12.9 13.3C8.3 29.3 8 69 8 152.6v122l2.6 5.3c1.7 3.4 4.1 6.2 6.7 7.9l4.1 2.7h252.2l4.1-2.7c2.6-1.7 5-4.5 6.7-7.9l2.6-5.3v-122c0-85.6-.3-123.3-1.1-126.1-1.4-5.1-8.1-11.6-13.6-13.3-5.5-1.6-245-1.6-250.3.1zm246.8 58.4.2 41.3h-75l-4-7.9c-4.3-8.7-6.8-11.1-11.6-11.1-5.7 0-8 3.1-19.1 26.1-5.8 12.1-10.9 21.7-11.1 21.4-.3-.3-6.7-14.8-14.1-32.2-10.5-24.3-14.2-32.1-16.4-33.6-3.5-2.7-9.1-2.6-12.1.3-1.3 1.1-5.7 9.9-9.7 19.5L88.5 113H26V72.2c0-22.5.3-41.2.7-41.5.3-.4 54.9-.6 121.2-.5l120.6.3.3 41.2zm-142.8 64c11.6 27.2 13.9 31.9 16.5 33.5 4.1 2.4 7.8 2.3 11.2-.6 1.8-1.5 6.8-10.4 13.7-24.5l10.9-22.1 1.8 2.9c3.8 6.3 2.3 6.1 47.6 6.1H269v71H26v-70.9l35.9-.3c32.9-.3 36.1-.4 37.9-2.1 1.1-1 4.1-7 6.8-13.2 2.6-6.3 5.1-11.5 5.4-11.5.3 0 6.6 14.3 14 31.7zm143 110.1c0 18.7-.3 26.1-1.2 27-1.7 1.7-238.9 1.7-240.6 0-.9-.9-1.2-8.3-1.2-27V220h243v25.8z"
        stroke={stroke}
        fill={fill}
      />
      <path
        d="M58.5 240.5c-5.6 5.5-1.8 15.5 6 15.5 4.8 0 8.5-3.6 8.5-8.4 0-4.8-.8-6.5-4.1-8.1-4.1-2.2-7.7-1.8-10.4 1z"
        stroke={stroke}
        fill={fill}
      />
      <path
        d="M105.9 240.9c-2 2-2.9 3.9-2.9 6.1 0 2.2.9 4.1 2.9 6.1 3.7 3.6 7.9 3.8 11.8.5 2.2-1.9 2.8-3.2 2.8-6.6 0-8.2-8.8-11.9-14.6-6.1z"
        stroke={stroke}
        fill={fill}
      />
    </g>
  </svg>
);

import { CarePlan, Communication, Composition } from "@bonfhir/core/r4b";

import { Alert, Flex, Stack, Tabs } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { RefObject, useEffect, useState } from "react";
import { DiagnosticReport } from "../pages/AppointmentPage";
import { RefButton } from "./Button";
import { DocumentationSection } from "./DocumentationSummary";

type Props = {
  generatedNotes: DiagnosticReport | undefined;
  carePlan: CarePlan | undefined;
  patientEducation: Communication | undefined;
  composition: Composition | undefined;
  /** @default false */
  finalized: boolean;
  saveBtnRef: RefObject<HTMLButtonElement>;
  visitNotes: string;
  setVisitNotes: (text: string) => void;
  doctorLetter: string;
  setDoctorLetter: (text: string) => void;
  treatmentPlan: string;
  setTreatmentPlan: (text: string) => void;
  onSaveAndSend: (
    visitNotes: string,
    doctorLetter: string,
    treatmentPlan: string,
    coding: string,
  ) => void;
  coding: string;
  setCoding: (text: string) => void;
};

export function Documentation({
  generatedNotes,
  carePlan,
  patientEducation,
  saveBtnRef,
  onSaveAndSend,
  finalized,
  visitNotes,
  setVisitNotes,
  doctorLetter,
  setDoctorLetter,
  treatmentPlan,
  setTreatmentPlan,
  composition,
  coding,
  setCoding,
}: Props) {
  // This will be set to false if any of the defined resources below returns false
  const [isSuccess, setIsSuccess] = useState(true);

  useEffect(() => {
    if (!generatedNotes) {
      setIsSuccess(false);
      return;
    }

    if (!visitNotes) {
      const content = generatedNotes.conclusion;
      setVisitNotes(content ?? "");
      // const data = content.length && content[0].attachment.data;
      // setVisitNotes(data ? atob(data) : "");
      setIsSuccess(true);
    }
  }, [generatedNotes]);

  useEffect(() => {
    if (!composition) {
      setIsSuccess(false);
      return;
    }
    if (!coding) {
      const content = composition.title;
      setCoding(content ?? "");
      setIsSuccess(true);
    }
  }, [composition]);

  useEffect(() => {
    if (!carePlan) {
      setIsSuccess(false);
      return;
    }
    if (!treatmentPlan) {
      const data = carePlan.description;

      if (!data?.length) {
        return;
      }
      setTreatmentPlan(data ?? "");
      setIsSuccess(true);
    }

    // TODO: decide where to show diagnostic report and ICD10 codes
    // if (!diagnosticReport || diagnosticReport.status !== "final") {
    //   setIsSuccess(false);
    //   return;
    // }

    // // TODO:: get coding as well
    // const forms = diagnosticReport.presentedForm;
    // // const codes = diagnosticReport.conclusionCode;

    // if (forms?.length) {
    //   const data = forms[0].data;

    //   setDoctorLetter(data ? atob(data) : "");
    // }

    // if (codes?.length) {
    //   let data = "";
    //   codes.forEach((code) => {
    //     const codingString = code.coding?.reduce(
    //       (res, coding) =>
    //         (res += coding.display ? `${coding.code} ${coding.display}` : ""),
    //       "",
    //     );
    //     data += `\n${codingString}`;
    //   });
    //
    //   setCodig(data);
    // }
  }, [carePlan]);

  useEffect(() => {
    if (!patientEducation) {
      setIsSuccess(false);
      return;
    }
    if (!doctorLetter) {
      const data = patientEducation.payload;
      if (!data?.length) {
        return;
      }
      setDoctorLetter(data[0].contentString ?? "");
      setIsSuccess(true);
    }
  }, [patientEducation]);

  const sections: Array<{
    title?: string;
    label?: string;
    description?: string;
    content: string;
    onChange: (text: string) => void;
    placeholder: string;
  }> = [
    {
      title: "Visit Notes",
      description:
        "You can copy these visit notes and manually paste them into your EHR.",
      content: visitNotes,
      onChange: (text) => {
        setVisitNotes(text);
      },
      placeholder: "Please add your visit notes.",
    },
    // {
    //   title: "Coding", // TODO:: Add coding as ain input
    //   description:
    //     "You can copy the ICD-10 code and manually paste it into your EHR.",
    //   content: coding,
    //   onChange: (text) => {
    //     setCodig(text);
    //   },
    //  placeholder: "Please add the ICD-10 code."
    // },
    {
      title: "Coding Support",
      description: `Please select the right data and use it for your EHR. Coding is always the responsibility of the treating provider.`,
      // label: "ICD 10 codes",
      content: coding,
      onChange: (text) => {
        setCoding(text);
      },
      placeholder: "Coding",
    },
    {
      title: "Patient Summary",
      description: "",
      label: "Doctor's letter",
      content: doctorLetter,
      onChange: (text) => {
        setDoctorLetter(text);
      },
      placeholder: "Please add a patient summary.",
    },
    {
      label: "Treatment plan",
      content: treatmentPlan,
      onChange: (text) => {
        setTreatmentPlan(text);
      },
      placeholder: "Please add a patient treatment plan.",
    },
  ];

  return (
    <Tabs.Panel value="documentation">
      <Stack align="center" gap="xl">
        <Alert
          variant="transparent"
          color="#7E4DD2"
          styles={{
            message: {
              color: "#7E4DD2",
            },
          }}
          title={
            isSuccess
              ? "AI Scribe was successful."
              : "Unfortunately, AI Scribe didn't work."
          }
          icon={<IconInfoCircle />}
        >
          {isSuccess
            ? "Please ensure that you review the contents of all outputs and confirm that they accurately represent the consultation"
            : "Please add your visit notes and review them before sending them to the patient."}
        </Alert>
        {sections.map((section, i) => (
          <DocumentationSection
            key={i}
            title={section.title}
            description={section.description}
            label={section.label}
            onChange={section.onChange}
            content={section.content}
            disabled={finalized}
            placeholder={section.placeholder}
          />
        ))}

        <Flex justify="center">
          <RefButton
            onClick={() => {
              onSaveAndSend &&
                onSaveAndSend(visitNotes, doctorLetter, treatmentPlan, coding);
            }}
            variant="filled"
            color="buttonAccent"
            size="lg"
            disabled={finalized}
            ref={saveBtnRef}
          >
            Save
          </RefButton>
        </Flex>
      </Stack>
    </Tabs.Panel>
  );
}

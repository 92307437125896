import { Flex, Title } from "@mantine/core";
import { useContext } from "react";
import { CurrentProfile } from "../integrations/bonfhir";
import { Logo } from "../ui-components/Logo";
import { ProfileAvatar } from "../ui-components/ProfileAvatar";

export function TitleBar() {
  const profile = useContext(CurrentProfile);
  return (
    <Flex px="md" align="center" justify="space-between">
      <Title ml="xl" order={1}>
        <Logo />
      </Title>
      <ProfileAvatar profile={profile} />
    </Flex>
  );
}

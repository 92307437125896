import {
  Appointment,
  FhirClientSearchParameters,
  Retrieved,
  WithResolvableReferences,
} from "@bonfhir/core/r4b";
import { useFhirSearch } from "@bonfhir/query/r4b";
import { FhirTable, FhirValue } from "@bonfhir/react/r4b";
import { Flex, Text } from "@mantine/core";
import { format } from "date-fns";
import { ReactNode } from "react";
import classes from "./AppointmentList.module.css";

type Props = {
  /**
   * If provided, this will be called once per appointment row and its output
   * will be displayed in the rightmost _actions_ column.
   */
  appointmentActions?(appointment: Appointment): ReactNode;
  query: FhirClientSearchParameters<"Appointment">;
  future?: boolean;
};
const APPOINTMENT_REFETCH_INTERVAL_MS = 30 * 1000; // 30 seconds

export function AppointmentTable({ appointmentActions, query, future }: Props) {
  const appointmentsQuery = useFhirSearch("Appointment", query, null, {
    query: {
      refetchInterval: APPOINTMENT_REFETCH_INTERVAL_MS,
    },
  });

  return (
    <FhirTable
      {...appointmentsQuery}
      rendererProps={{
        tr: {
          className: classes.tr,
        },
        table: {
          className: classes.table,
        },
        td: {
          className: classes.td,
        },
      }}
      columns={[
        {
          key: "time",
          title: null,
          render: ({ start, end, status }: Appointment) => {
            if (!start || !end) {
              return null;
            }

            const formattedStart = format(start, "h:mm a");
            const formattedEnd = format(end, "h:mm a");
            const formattedDate = format(start, "MM/dd/yyyy");

            // const isInPast = isPast(end) || status === "fulfilled";
            const isInPast = status === "fulfilled";

            return isInPast || future ? (
              <Flex gap="sm" direction="column" align="start">
                <FhirValue
                  type="date"
                  value={formattedDate}
                  rendererProps={{
                    text: {
                      size: "lg",
                      c: "textSecondary",
                      fw: "bold",
                    },
                  }}
                />
                <Text size="lg" c="textSecondary">
                  {formattedStart} - {formattedEnd}
                </Text>
              </Flex>
            ) : (
              <Text size="lg" c="textSecondary">
                {formattedStart} - {formattedEnd}
              </Text>
            );
          },
        },
        {
          key: "title",
          title: null,
          render: ({ description }: Appointment) => {
            return (
              <FhirValue
                type="string"
                value={description}
                rendererProps={{
                  text: {
                    size: "lg",
                    fw: "bolder",
                  },
                }}
              />
            );
          },
        },
        {
          key: "description",
          title: null,
          render: ({
            comment,
            participant,
          }: WithResolvableReferences<Retrieved<Appointment>>) => {
            const patientReference = participant.find((v) =>
              v.actor?.reference?.includes("Patient"),
            );

            return (
              <Flex direction="column" gap="xs">
                <Text>{patientReference?.actor?.display}</Text>
                {/* {patient && <FhirValue type="HumanName" value={patient.name} />} */}
                <FhirValue
                  type="string"
                  value={comment}
                  rendererProps={{
                    text: {
                      c: "textSecondary",
                      size: "md",
                    },
                  }}
                />
              </Flex>
            );
          },
        },
        {
          key: "actions",
          title: null,
          render: (appointment) =>
            appointmentActions ? appointmentActions(appointment) : null,
        },
      ]}
    />
  );
}

import { Formatter } from "@bonfhir/core/r4b";
import { Title } from "@mantine/core";
import { useContext } from "react";
import { CurrentProfile } from "../integrations/bonfhir.tsx";

export function Me() {
  const currentPatient = useContext(CurrentProfile);

  return (
    <Title order={2}>
      Welcome, {Formatter.default.format("HumanName", currentPatient.name)}
    </Title>
  );
}

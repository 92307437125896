import {
  Box,
  Container,
  Flex,
  Grid,
  Group,
  Button as IconButton,
  Loader,
  Modal,
  Space,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { DateInput, DateTimePicker } from "@mantine/dates";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate, useParams } from "react-router-dom";
import { SERVER_URL } from "../app-configuration";
import { forms } from "../constants/visitResons";
import {
  BackPain,
  BloodPressure,
  ColdAndFlu,
  Diabetes,
  Headache,
  HeartProblems,
  JointPain,
  MedicalClipboard,
  Menopause,
  PrescriptionRefill,
  SkinRash,
  SleepingProblems,
  WeightLoss,
} from "../icons";
import { Button } from "../ui-components/Button";
import { DocumentationSection } from "../ui-components/DocumentationSummary";
import classNames from "./Appointment.module.css";

export function NewAppointment() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState<Date | null>();
  const [start, setStart] = useState<Date | null>(new Date());
  const [appointmentReason, setAppointmentReason] = useState("");
  const [medicalHistory, setMedicalHistory] = useState("");
  const [virtual, setVirtual] = useState<boolean>();
  const [newPatient, setNewPatient] = useState<boolean>();
  const [intakeForm, setIntakeForm] = useState<boolean>();
  const [visitForm, setVisitForm] = useState<string>();
  const [isValid, setIsValid] = useState(false);
  const [showVisitReason, setShowVisitReason] = useState(false);
  const [showConfirmVisit, setShowConfirmVisit] = useState(false);
  const { scribe } = useParams();
  const visitReasons = [
    {
      icon: <BackPain width={50} height={50} stroke="#FFF" />,
      label: "Back Pain",
      form: forms.backAndNeckPain,
    },
    {
      icon: <BloodPressure width={50} height={50} stroke="#FFF" />,
      label: "Blood Pressure",
      form: forms.bloodPressure,
    },
    {
      icon: <ColdAndFlu width={50} height={50} stroke="#FFF" />,
      label: "Cold And Flu",
      form: forms.coldFlu,
    },
    {
      icon: <Diabetes width={50} height={50} stroke="#FFF" />,
      label: "Diabetes",
      form: forms.diabetes,
    },
    {
      icon: <Headache width={50} height={50} stroke="#FFF" />,
      label: "Headache",
      form: forms.headacheMigraine,
    },
    {
      icon: <HeartProblems width={50} height={50} stroke="#FFF" />,
      label: "Heart Problems",
      form: forms.heartProblems,
    },
    {
      icon: <JointPain width={50} height={50} stroke="#FFF" />,
      label: "Joint Pain",
      form: forms.jointPain,
    },
    {
      icon: <Menopause width={50} height={50} stroke="#FFF" />,
      label: "Menopause",
      form: forms.menopause,
    },
    {
      icon: <PrescriptionRefill width={50} height={50} stroke="#FFF" />,
      label: "Prescription Refill",
      form: forms.prescriptionRefill,
    },
    {
      icon: <SkinRash width={50} height={50} />,
      label: "Skin Rash",
      form: forms.skinIssues,
    },
    {
      icon: <SleepingProblems width={50} height={50} />,
      label: "Sleeping Problems",
      form: forms.sleepingProblems,
    },
    {
      icon: <WeightLoss width={50} height={50} fill="#FFF" />,
      label: "Weight Loss",
      form: forms.weightLoss,
    },
  ];
  useEffect(() => {
    if (scribe === "scribe") {
      setVirtual(false);
    }
  }, [scribe]);
  useEffect(() => {
    if (
      loading ||
      !firstName ||
      !lastName ||
      !start ||
      newPatient === undefined ||
      !dob
    ) {
      setIsValid(false);
    } else if (virtual) {
      if (
        (intakeForm && !visitForm) ||
        intakeForm === undefined ||
        !email ||
        !phone
      ) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    } else {
      setIsValid(true);
    }
  }, [
    loading,
    firstName,
    lastName,
    email,
    phone,
    start,
    appointmentReason,
    medicalHistory,
    virtual,
    newPatient,
    intakeForm,
    visitForm,
    dob,
  ]);

  const submit = () => {
    const appointmentData = {
      firstName,
      lastName,
      email,
      start,
      appointmentReason,
      medicalHistory,
      phone,
      virtual,
      newPatient,
      intakeForm,
      visitForm,
      dob: dob?.toISOString().split("T")[0],
    };
    setShowConfirmVisit(false);
    setLoading(true);
    window.scrollTo(0, 0);
    const token = auth?.user?.access_token;
    fetch(`${SERVER_URL}/appointment`, {
      method: "POST",
      headers: {
        Authorization: `bearer ${token}`,
        "content-type": "application/json",
      },
      body: JSON.stringify(appointmentData),
    })
      .then((res) => res.json())
      .then((res) => {
        const queryString = encodeURIComponent(
          `${firstName} ${lastName} on ${format(start ?? "", "MMMM dd, yyyy h:mm a")}`,
        );
        setFirstName("");
        setLastName("");
        setEmail("");
        setStart(null);
        setAppointmentReason("");
        setMedicalHistory("");
        setPhone("");
        setVirtual(undefined);
        setNewPatient(undefined);
        setIntakeForm(undefined);
        setVisitForm(undefined);
        setDob(undefined);

        setLoading(false);
        navigate(
          res.appointmentId && !virtual
            ? `/appointment/${res.appointmentId}`
            : `/confirmation/${queryString}/${intakeForm?.toString()}`,
        );
      })
      .catch((e) => console.log(e));
  };

  return (
    <Container size="md" p="lg" className={classNames.root}>
      <Space h="lg" />
      <Space h="lg" />

      {loading ? (
        <>
          <Loader size="lg" variant="bars" />
          <Text fz="xl">Creating Appointment...</Text>
        </>
      ) : (
        <>
          <Flex justify="space-between" align="center" mb="xl">
            <Title order={1} style={{ marginLeft: "20px" }}>
              {scribe === "scribe" ? "Scribe a Visit" : "Prepare a Visit"}.
            </Title>
          </Flex>

          {scribe !== "scribe" && (
            <Text style={{ marginLeft: "20px" }}>
              Please provide me with some patient data.
            </Text>
          )}
          <Text style={{ marginLeft: "20px" }}>
            {scribe === "scribe"
              ? `Scribe your first visit by filling in some data and clicking the "Start the Visit" button`
              : "If you want your assistant to prepare your visits, get a free additional assistant account"}
            .
          </Text>
          <Space h="md" />
          <Stack align="start" w="100%">
            <Box
              w="100%"
              className={classNames.contentContainer}
              p="md"
              bg="white"
            >
              <Table w="100%">
                <Table.Tbody>
                  <Table.Tr key="newAppointmentType">
                    {scribe === "prepare" && (
                      <>
                        <Table.Td>
                          <Text fz="xl">What type of visit is this?</Text>
                        </Table.Td>
                        <Table.Td>
                          <Button
                            variant={virtual === true ? "filled" : "outline"}
                            color={
                              virtual === true
                                ? "buttonSecondary"
                                : "buttonPrimary"
                            }
                            size="md"
                            onClick={() => setVirtual(true)}
                            style={{ width: "49%" }}
                          >
                            Virtual visit
                          </Button>
                          <Button
                            variant={virtual === false ? "filled" : "outline"}
                            color={
                              virtual === false
                                ? "buttonSecondary"
                                : "buttonPrimary"
                            }
                            size="md"
                            onClick={() => setVirtual(false)}
                            style={{ width: "49%", marginLeft: "2%" }}
                          >
                            In-office visit
                          </Button>
                        </Table.Td>
                      </>
                    )}
                  </Table.Tr>
                  <Table.Tr key="newAppointmentNewPatient">
                    <Table.Td>
                      <Text fz="xl">Is this a new or established patient?</Text>
                    </Table.Td>
                    <Table.Td>
                      <Button
                        variant={newPatient === true ? "filled" : "outline"}
                        color={
                          newPatient === true
                            ? "buttonSecondary"
                            : "buttonPrimary"
                        }
                        size="md"
                        onClick={() => setNewPatient(true)}
                        style={{ width: "49%" }}
                      >
                        New patient
                      </Button>
                      <Button
                        variant={newPatient === false ? "filled" : "outline"}
                        color={
                          newPatient === false
                            ? "buttonSecondary"
                            : "buttonPrimary"
                        }
                        size="md"
                        onClick={() => setNewPatient(false)}
                        style={{ width: "49%", marginLeft: "2%" }}
                      >
                        Established patient
                      </Button>
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr key="inPersonAppointmentFirstName">
                    <Table.Td>
                      <Text fz="xl">First Name</Text>
                    </Table.Td>
                    <Table.Td>
                      <TextInput
                        size="md"
                        value={firstName}
                        onChange={(e) => setFirstName(e.currentTarget.value)}
                        placeholder="Enter first name"
                        style={{ minWidth: 200 }}
                        radius={40}
                      />
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr key="inPersonAppointmentLastName">
                    <Table.Td>
                      <Text fz="xl">Last Name</Text>
                    </Table.Td>
                    <Table.Td>
                      <TextInput
                        size="md"
                        value={lastName}
                        onChange={(e) => setLastName(e.currentTarget.value)}
                        placeholder="Enter last name"
                        style={{ minWidth: 200 }}
                        radius={40}
                      />
                    </Table.Td>
                  </Table.Tr>
                  {virtual && (
                    <>
                      <Table.Tr key="inPersonAppointmentEmail">
                        <Table.Td>
                          <Text fz="xl">Email Address</Text>
                        </Table.Td>
                        <Table.Td>
                          <TextInput
                            size="md"
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            placeholder="Enter email address"
                            style={{ minWidth: 200 }}
                            radius={40}
                          />
                        </Table.Td>
                      </Table.Tr>
                      <Table.Tr key="newAppointmentPhone">
                        <Table.Td>
                          <Text fz="xl">Phone Number</Text>
                        </Table.Td>
                        <Table.Td>
                          <TextInput
                            size="md"
                            type="number"
                            value={phone}
                            onChange={(e) => setPhone(e.currentTarget.value)}
                            placeholder="Enter phone number"
                            style={{ minWidth: 200 }}
                            radius={40}
                          />
                        </Table.Td>
                      </Table.Tr>
                    </>
                  )}
                  <Table.Tr key="newAppointmentDob">
                    <Table.Td>
                      <Text fz="xl">Date Of Birth</Text>
                    </Table.Td>
                    <Table.Td>
                      <DateInput
                        size="md"
                        placeholder="Select a date"
                        value={dob}
                        onChange={setDob}
                        style={{ minWidth: 200 }}
                        radius={40}
                      />
                    </Table.Td>
                  </Table.Tr>
                  {scribe === "prepare" && (
                    <Table.Tr key="newAppointmentStartTime">
                      <Table.Td>
                        <Text fz="xl">Appointment Time</Text>
                      </Table.Td>
                      <Table.Td>
                        <DateTimePicker
                          size="md"
                          value={start}
                          onChange={setStart}
                          placeholder="Select a date and time"
                          label="Start Time"
                          style={{ minWidth: 200 }}
                          radius={40}
                        />
                      </Table.Td>
                    </Table.Tr>
                  )}
                  {virtual && newPatient !== undefined && (
                    <Table.Tr key="newAppointmentIntake">
                      <Table.Td>
                        <Text fz="xl">Who enters intake data?</Text>
                      </Table.Td>
                      <Table.Td>
                        <Button
                          variant={intakeForm === true ? "filled" : "outline"}
                          color={
                            intakeForm === true
                              ? "buttonSecondary"
                              : "buttonPrimary"
                          }
                          size="md"
                          onClick={() => setIntakeForm(true)}
                          style={{ width: "49%" }}
                        >
                          Send patient form
                        </Button>
                        <Button
                          variant={intakeForm === false ? "filled" : "outline"}
                          color={
                            intakeForm === false
                              ? "buttonSecondary"
                              : "buttonPrimary"
                          }
                          size="md"
                          onClick={() => setIntakeForm(false)}
                          style={{ width: "49%", marginLeft: "2%" }}
                        >
                          Enter intake data
                        </Button>
                      </Table.Td>
                    </Table.Tr>
                  )}
                  {intakeForm === true && (
                    <Table.Tr key="newAppointmentVisitReason">
                      <Table.Td>
                        <Button
                          variant={visitForm ? "filled" : "outline"}
                          color={
                            visitForm ? "buttonSecondary" : "buttonPrimary"
                          }
                          size="md"
                          onClick={() => setShowVisitReason(true)}
                          style={{ width: "100%" }}
                        >
                          Select Intake Form
                        </Button>
                      </Table.Td>
                      <Table.Td>
                        {visitForm && (
                          <Flex align="center">
                            <Text fz="xl">
                              {
                                visitReasons.find((v) => v.form === visitForm)
                                  ?.label
                              }
                            </Text>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#44b7b0",
                                padding: "5px",
                                marginLeft: "20px",
                                borderRadius: "20%",
                              }}
                            >
                              {
                                visitReasons.find((v) => v.form === visitForm)
                                  ?.icon
                              }
                            </div>
                          </Flex>
                        )}
                      </Table.Td>
                    </Table.Tr>
                  )}
                </Table.Tbody>
              </Table>

              <Space h="lg" />
              {(virtual === false || intakeForm === false) && (
                <>
                  <DocumentationSection
                    title="Medical History/Medication/Vitals"
                    content={medicalHistory}
                    onChange={(text) => setMedicalHistory(text)}
                    disabled={false}
                    placeholder={`Please tell me about the patients chronic disease, medication and if you have data from medical tests. 

I will summarize for you ahead of the visit and combine it with the patient encounter.

This will lead to a comprehensive documentation, capturing the full value for coding. 

If you have no data, just leave it blank and I will use data from your conversation with the patient.`}
                    copy={false}
                  />

                  <Space h="md" />

                  <DocumentationSection
                    title="Appointment Reason"
                    content={appointmentReason}
                    onChange={(text) => setAppointmentReason(text)}
                    disabled={false}
                    placeholder="Enter the reason for the appointment"
                    copy={false}
                  />

                  <Space h="lg" />
                </>
              )}
              <Group style={{ paddingLeft: 10 }}>
                <Button
                  variant="filled"
                  color="buttonPrimary"
                  size="md"
                  disabled={!isValid}
                  onClick={() =>
                    virtual ? setShowConfirmVisit(true) : submit()
                  }
                  style={{ width: "28%" }}
                >
                  <Text>
                    {isValid ? <MedicalClipboard height={15} width={15} /> : ""}
                    {isValid ? " " : ""}

                    {scribe === "scribe" ? "Start a Visit" : "Prepare a Visit"}
                  </Text>
                </Button>
              </Group>
            </Box>
          </Stack>
        </>
      )}

      <Modal
        opened={showConfirmVisit}
        onClose={() => {
          setShowConfirmVisit(false);
        }}
        size="lg"
        p="xl"
      >
        <Flex justify="center">
          <Stack w="100%" p="sm" align="center">
            <Title ta="center" order={2}>
              Please confirm your selection
            </Title>
            <Text fz="xl">
              Based on your selection, I will now{" "}
              {intakeForm
                ? `send the patient an intake form to help me with precharting for their virtual visit`
                : `confirm the virtual visit for your patient. It can be found in your upcoming visits`}
              .
              <Space h="lg" />I will also provide the patient with the link for
              the virtual visit and make sure the patient understands how to
              join the appointment.
            </Text>
            <Button
              variant="filled"
              color="buttonAction"
              size="md"
              disabled={!isValid}
              onClick={() => submit()}
            >
              {intakeForm ? "Send Intake Form" : "Yes, I confirm"}
            </Button>
          </Stack>
        </Flex>
      </Modal>

      <Modal
        opened={showVisitReason}
        onClose={() => {
          setShowVisitReason(false);
        }}
        size="lg"
        p="xl"
      >
        <Flex justify="center">
          <Stack w="100%" p="sm" align="center">
            <Title ta="center" order={2}>
              Please select the visit reason.
            </Title>
            <Grid gutter="sm">
              {visitReasons.map(({ icon, label, form }, index) => (
                <Grid.Col span={4} key={index}>
                  <Stack>
                    <IconButton
                      color={
                        visitForm === form ? "buttonAction" : "buttonDisabled"
                      }
                      variant="filled"
                      fullWidth
                      onClick={() => {
                        setVisitForm(form);
                        setShowVisitReason(false);
                      }}
                      style={{
                        height: 150,
                        width: 150,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        {icon}
                        <Text
                          size="xs"
                          mt="sm"
                          style={{
                            width: "100%",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                          }}
                        >
                          {label}
                        </Text>
                      </div>
                    </IconButton>
                  </Stack>
                </Grid.Col>
              ))}
            </Grid>
          </Stack>
        </Flex>
      </Modal>
    </Container>
  );
}

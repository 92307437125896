import {
  Address,
  Appointment,
  Attachment,
  Coverage,
  Formatter,
  Observation,
} from "@bonfhir/core/r4b";
import {
  format as dateFormat,
  differenceInYears,
  isFuture,
  isToday,
  isTomorrow,
  // isWithinInterval,
  isYesterday,
  parseISO,
} from "date-fns";

export function prettyAppointmentDate(appointment: Appointment): string {
  if (!appointment.start || !appointment.end) {
    return "";
  }

  const startDate = parseISO(appointment.start);
  const endDate = parseISO(appointment.end);

  const startTime = dateFormat(startDate, "HH:mm");
  const endTime = dateFormat(endDate, "HH:mm a");

  if (isToday(startDate)) {
    return `Today, ${startTime} - ${endTime}`;
  }

  if (isTomorrow(startDate)) {
    return `Tomorrow, ${startTime} - ${endTime}`;
  }

  if (isYesterday(startDate)) {
    return `Yesterday, ${startTime} - ${endTime}`;
  }

  return dateFormat(startDate, "dd MMMM, HH:mm") + ` - ${endTime}`;
}

export function isOngoing(appointment: Appointment): boolean { //, date: Date
  if (!appointment.start || !appointment.end) {
    return false;
  }
  return appointment.status === "arrived";
  // const startDate = parseISO(appointment.start);
  // const endDate = parseISO(appointment.end);

  // return isWithinInterval(date, {
  //   start: startDate,
  //   end: endDate,
  // });
}

export function appointmentInFuture(appointment: Appointment): boolean {
  if (!appointment.end) {
    return false;
  }

  return isFuture(parseISO(appointment.end));
}

const fhirFormatter = Formatter.default;

export function calculateBMI(
  observations: Observation[] | undefined,
): string | null {
  if (!observations) {
    return null;
  }

  let weight = null;
  let height = null;
  let bmi = null;

  // https://loinc.org/29463-7
  const weightCode = [
    "29463-7",
    "3141-9",
    "3142-7",
    "75292-3",
    "79348-9",
    "8350-1",
    "8351-9",
  ];

  //  https://loinc.org/LG34373-7
  const heightCodes = ["3137-7", "3138-5", "8302-2", "8306-3", "8308-9"];
  // https://www.findacode.com/loinc/39156-5--body-mass-index-bmi-ratio.html
  const bmiCode = "39156-5";

  for (const observation of observations) {
    const code = (observation.code.coding && observation.code.coding[0].code) ?? ""; //fhirFormatter.format("CodeableConcept", observation.code);
    if (code.includes(bmiCode)) {
      bmi = fhirFormatter.format("Quantity", observation.valueQuantity);
      break;
    }

    if (weight && height) {
      break;
    }

    if (!weight && weightCode.find((c) => code.includes(c))) {
      weight =
        Number(
          fhirFormatter.format("decimal", observation.valueString),
        ) || 0;
    }

    if (!height && heightCodes.find((c) => code.includes(c))) {
      height =
        Number(
          fhirFormatter.format("decimal", observation.valueString),
        ) || 0;
    }
  }

  if (!bmi && weight && height) {
    const heightInInches = height; // Assuming height is given in inches
    const weightInPounds = weight; // Assuming weight is given in pounds

    bmi = ((weightInPounds / (heightInInches * heightInInches)) * 703).toFixed(
      2,
    );
  }

  return bmi ? String(bmi) : null;
}

export const getCountry = (address: Address[] | undefined) => {
  if (!address) {
    return "United States";
  }

  return address.find((a) => a.country)?.country || "United States";
};

export const getAttachemnt = (attachments: Attachment[] | undefined) => {
  if (!attachments) {
    return null;
  }

  return attachments[0];
};

type InsuranceData = {
  carrier?: string;
  subscriberId?: string;
  plan?: string;
  endDate?: string;
  groupId?: string;
};

export function getCoverageData(
  coverage: Coverage | undefined,
): InsuranceData | undefined {
  // If no coverage is provided, return unknown for all fields
  if (!coverage) {
    return {
      carrier: "unknown",
      subscriberId: "unknown",
      plan: "unknown",
      endDate: "unknown",
      groupId: "unknown",
    };
  }

  const carrier = fhirFormatter.format("Reference", coverage.payor[0]);
  const subscriberId = fhirFormatter.format("string", coverage.subscriberId);
  const endDate = fhirFormatter.format("dateTime", coverage.period?.end);
  let plan = undefined;
  let groupId = undefined;

  if (!coverage.class?.length) {
    return {
      carrier,
      subscriberId,
      endDate,
      plan: "unknown",
      groupId: "unknown",
    };
  }

  for (const c of coverage.class) {
    const code = fhirFormatter.format("CodeableConcept", c.type);

    if (plan && groupId) {
      break;
    }

    if (!plan && code.toLowerCase().includes("plan")) {
      plan = c.name;
    }

    if (!groupId && code.toLowerCase().includes("group")) {
      groupId = c.name;
    }
  }

  return {
    carrier,
    subscriberId,
    plan,
    endDate,
    groupId,
  };
}

export function calculateAge(birthDate: string) {
  const birth = new Date(birthDate);
  const today = new Date();
  return String(differenceInYears(today, birth));
}

import { Box, Container, Flex, Space, Stack, Table, Text, Tabs, Loader, Switch } from "@mantine/core";
import { useCallback, useContext, useState } from "react";
import { Button } from "../ui-components/Button";
import classNames from "./Appointment.module.css";
import { CurrentProfile } from "../integrations/bonfhir";
import { TimeInput, DateInput } from "@mantine/dates";
import moment from "moment";
import { useAuth } from "react-oidc-context";
import { SERVER_URL } from "../app-configuration";
import { useFhirPatchMutation, useFhirSearch } from "@bonfhir/query/r4b";
import { ScheduleFhirSearchBuilder, SlotFhirSearchBuilder, reference } from "@bonfhir/core/r4b";
import { format } from "date-fns";
import { FaTrash } from "react-icons/fa";

type AvailabilityDayType = {
	start: string;
	end: string;
	error: boolean;
};

type DayType = "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday";

type TimeChangePropsType = {
	index: number;
	day: AvailabilityDayType[];
	setDay: (a: AvailabilityDayType[]) => void;
	key: "start" | "end";
	value: string;
};

type RevmoveItemPropsType = {
	index: number;
	day: AvailabilityDayType[] | undefined;
	setDay: (a: AvailabilityDayType[]) => void;
};

export function AvailabilitiesPage() {
	const profile = useContext(CurrentProfile);
	const auth = useAuth();
	const [date, setDate] = useState<Date | null>(new Date);
	const [sunday, setSunday] = useState<AvailabilityDayType[]>();
	const [monday, setMonday] = useState<AvailabilityDayType[]>();
	const [tuesday, setTuesday] = useState<AvailabilityDayType[]>();
	const [wednesday, setWednesday] = useState<AvailabilityDayType[]>();
	const [thursday, setThursday] = useState<AvailabilityDayType[]>();
	const [friday, setFriday] = useState<AvailabilityDayType[]>();
	const [saturday, setSaturday] = useState<AvailabilityDayType[]>();

	const [activeTab, setActiveTab] = useState<string | null>("new");
	const [loading, setLoading] = useState(false);

	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const handleTimeChange = ({ index, day, setDay, key, value }: TimeChangePropsType): void => {
		const updated = { ...day[index], [key]: value, error: false };
		const startTime = updated.start.split(":");
		const endTime = updated.end.split(":");
		// const startHour = parseInt(startTime[0], 10);
		// const endHour = parseInt(endTime[0], 10);
		const startMinute = parseInt(startTime[1], 10);
		const endMinute = parseInt(endTime[1], 10);

		if (key === "end" && startMinute != endMinute) updated.error = true;
		if (day.length === 1) {
			setDay([updated]);
		} else {
			setDay([...day.slice(0, index), updated, ...day.slice(index + 1)]);
		}
	};

	const scheduleQuery = useCallback(
		(builder: ScheduleFhirSearchBuilder): ScheduleFhirSearchBuilder => {
			return builder.actor(reference(profile))
		},
	[profile],
	);
	const scheduleResult = useFhirSearch("Schedule", scheduleQuery, null, {
		query: {
			refetchInterval: 60 * 1000,
		},
	});

	const schedule = scheduleResult.data?.bundle.entry && scheduleResult.data?.bundle.entry[0].resource;
	const now = new Date().toISOString();
	const formattedDate = format(now,
		"yyyy-MM-dd'T'HH:mm:ss",
	);
	const slotsQuery = useCallback(
		(builder: SlotFhirSearchBuilder): SlotFhirSearchBuilder => {
			return builder.schedule(reference(schedule)).status('free').start(`gt${formattedDate}`)._sort('start')._count(100)
		},
	[schedule],
	);

	const slotsResult = useFhirSearch("Slot", slotsQuery, null, {
		query: {
			// refetchInterval: 20 * 1000,
			enabled: !!schedule,
		},
	});

	const slots = slotsResult.data?.searchMatch().map((s) => ({ id: s.id, start: s.start, end: s.end }));

	const patchSlotMutation = useFhirPatchMutation("Slot");

	const submit = () => {
		if(loading) return;
		setLoading(true);
		const body = {
			sunday,
			monday,
			tuesday,
			wednesday,
			thursday,
			friday,
			saturday
		};
		let valid = true;
		Object.keys(body).forEach((k) => {
			const key = k as DayType;
			const day = body[key];
			day?.forEach((slot: AvailabilityDayType) => {
				if (slot.start.split(":")[1] != slot.end.split(":")[1]) {
					valid = false;
				}
			});
		});
		if (valid) {
			const token = auth.user?.access_token;
			fetch(`${SERVER_URL}/provider/availabilities`, {
				method: "POST",
				headers: {
					Authorization: `bearer ${token}`,
					"content-type": "application/json",
				},
				body: JSON.stringify({ ...body, date: date?.toISOString(), timezone}),
			})
				.then((res) => {
					if (res.status === 200) {
						setDate(new Date);
						setSunday(undefined);
						setMonday(undefined);
						setTuesday(undefined);
						setWednesday(undefined);
						setThursday(undefined);
						setFriday(undefined);
						setSaturday(undefined);
						slotsResult.refetch();
						setActiveTab("current");
						setLoading(false);
					} else {
						alert("Something went wrong, pleas contact support");
					}
				})
				.catch((e) => console.log(e));
		} else {
			alert("Please choose valid times");
			setLoading(false);
		}
	};

	const removeItem = (props: RevmoveItemPropsType) => {
		const { day, setDay, index } = props;
		if (day) {
			if (day.length === 1) {
				setDay([]);
			} else if (index === 0) {
				setDay(day.slice(1));
			} else {
				setDay([...day.slice(0, index), ...day.slice(index + 1)]);
			}
		}
	};
	const removeSlot = (id: string) => {
		patchSlotMutation.mutate({
			id,
			body: (patch) => patch.replace("/status", "busy"),
		});
	}

	const daysOfWeek = [
		{ day: "sunday", days: sunday, setDays: setSunday },
		{ day: "monday", days: monday, setDays: setMonday },
		{ day: "tuesday", days: tuesday, setDays: setTuesday },
		{ day: "wednesday", days: wednesday, setDays: setWednesday },
		{ day: "thursday", days: thursday, setDays: setThursday },
		{ day: "friday", days: friday, setDays: setFriday },
		{ day: "saturday", days: saturday, setDays: setSaturday },
	];

	return (
		<Container fluid className={classNames.root}>
			<Stack gap="sm" align="start" w="100%">
				<Stack className={classNames.contentContainer} bg="backgroundPrimary">
					<Space h="lg" />
					<Flex justify="space-between" gap="md" w="100%">
						<Box flex={0} miw="35%">
							<Tabs value={activeTab} onChange={setActiveTab}>
								<Tabs.List>
									<Tabs.Tab value="current">Current slots</Tabs.Tab>
									<Tabs.Tab value="new">Add slots</Tabs.Tab>
								</Tabs.List>

								<Tabs.Panel value="current">
									<Table horizontalSpacing="xl" verticalSpacing="sm">
										<Table.Tbody>
											<Table.Tr>
												<Table.Td>
													<b>Start</b>
												</Table.Td>
												<Table.Td>
													<b>End</b>
												</Table.Td>
												<Table.Td>
													<b>Remove</b>
												</Table.Td>
												<br />
											</Table.Tr>
											{slots?.map((s) => (
												<Table.Tr key={`slot-${s.id}}`}>
													<Table.Td style={{minWidth: 300}}>
														<Text>{format(s.start, "MMMM dd, yyyy h:mm a")}</Text>
													</Table.Td>
													<Table.Td style={{minWidth: 300}}>
														<Text>{format(s.end, "MMMM dd, yyyy h:mm a")}</Text>
													</Table.Td>
													<Table.Td style={{minWidth: 300}}>
														<FaTrash onClick={() => removeSlot(s.id)} style={{marginTop: 10, cursor: "pointer"}} />
													</Table.Td>													
												</Table.Tr>))}
										</Table.Tbody>
									</Table>
								</Tabs.Panel>

								<Tabs.Panel value="new">
									{loading ? <Loader /> :
									<Table horizontalSpacing="sm" verticalSpacing="sm">
										<Table.Tbody>
											<Space h="lg" />
											{daysOfWeek.map((d) => (
												<Table.Tr key={`availabilities-${d.day}`} style={{padding: 0}}>
													<Table.Td>
														<Switch color="black" label={d.day[0].toUpperCase() + d.day.slice(1)} checked={!!d.days} onChange={(e) => d.setDays(e.currentTarget.checked ? [{ start: moment().format("HH:mm").toString(), end: moment().format("HH:mm").toString(), error: false }] : undefined)}/>
													</Table.Td>
													{d.days?.map(({ start, end, error }, index) => (
														<Container key={`${d.day}-${index}`}>
															{error && (
																<Table.Td style={{ minWidth: 400 }}>
																	<Text c="red">Slots must start and end at the same minute, e.g. 11:14am - 12:14pm or multiple hours 8:12am-4:12pm</Text>
																</Table.Td>
															)}
															<Table.Td>
																<TimeInput
																	key={index}
																	value={start}
																	onChange={(event) =>
																		handleTimeChange({ index, day: d.days ?? [], setDay: d.setDays, key: "start", value: event.target.value })
																	}
																	style={{ minWidth: 200 }}
																/>
															</Table.Td>
															<Table.Td>
																<TimeInput
																	key={index}
																	value={end}
																	onChange={(event) =>
																		handleTimeChange({ index, day: d.days ?? [], setDay: d.setDays, key: "end", value: event.target.value })
																	}
																	style={{ minWidth: 200 }}
																/>
															</Table.Td>
															<Table.Td>
															{index === 0 ? <Text
																	style={{ cursor: "pointer"}}
																	onClick={() => d.setDays([...d.days ?? [], { start: moment().format("HH:mm").toString(), end: moment().format("HH:mm").toString(), error: false }])}>
																	+
																</Text>	: <Text
																	style={{ cursor: "pointer" }}
																	onClick={() => removeItem({ day: d.days, setDay: d.setDays, index })}>
																	-
																</Text>}
															</Table.Td>
														</Container>
													))}
												</Table.Tr>
											))}
												<Space h="lg" />
											<Table.Tr>
												<Table.Td>
													<DateInput
														value={date}
														onChange={setDate}
														placeholder="Pick a date"
														label="Create slots until"
														style={{ minWidth: 200 }} />
												</Table.Td>
												<Table.Td style={{textAlign: "right", paddingRight: 100, marginTop: 75}}>
													<Button
														variant="outline"
														color="black"
														size="md"
														onClick={() => submit()}>
															Save
													</Button>
												</Table.Td>
											</Table.Tr>
										</Table.Tbody>
									</Table>}
								</Tabs.Panel>
							</Tabs>
						</Box>
					</Flex>
				</Stack>
			</Stack>
			<Space h="lg" />
		</Container>
	);
}

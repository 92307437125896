export const MedicalClipboard = ({
  width = 24,
  height = 24,
  stroke = "#495057",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.47375 3.93041H5.1C4.49249 3.93041 4 4.4229 4 5.03042V21.9C4 22.5075 4.49249 23 5.1 23H19.1105C19.718 23 20.2105 22.5075 20.2105 21.9V5.03041C20.2105 4.4229 19.718 3.93041 19.1105 3.93041H16.705M14.2501 2.52131H15.605C16.2125 2.52131 16.705 3.01379 16.705 3.62131V4.71818C16.705 5.32569 16.2125 5.81818 15.605 5.81818H8.57376C7.96624 5.81818 7.47375 5.32569 7.47375 4.71818V3.62131C7.47375 3.01379 7.96624 2.52131 8.57375 2.52131H9.94127C9.94456 2.52131 9.94722 2.51865 9.94722 2.51536C9.94722 1.67845 10.6257 0.999997 11.4626 1L12.7288 1.00001C13.5657 1.00001 14.2441 1.67846 14.2441 2.51536C14.2441 2.51865 14.2468 2.52131 14.2501 2.52131ZM12.3333 9.99999C12.8856 9.99999 13.3333 10.4477 13.3333 11C13.3333 11.5555 13.3333 12.1111 13.3333 12.6667H15C15.5523 12.6667 16 13.1144 16 13.6667V14.3333C16 14.8856 15.5523 15.3333 15 15.3333C14.4444 15.3333 13.8889 15.3333 13.3333 15.3333V17C13.3333 17.5523 12.8856 18 12.3333 18H11.6667C11.1144 18 10.6667 17.5523 10.6667 17V15.3333C10.1111 15.3333 9.55556 15.3333 9 15.3333C8.44772 15.3333 8 14.8856 8 14.3333V13.6667C8 13.1144 8.44772 12.6667 9 12.6667L10.6667 12.6667C10.6667 12.1111 10.6667 11.5555 10.6667 11C10.6667 10.4477 11.1144 9.99999 11.6667 9.99999H12.3333Z"
      stroke={stroke}
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
);

import { Box, Container, Flex, Grid, Modal, Space, Stack, Text, Title, Table, TextInput, Select } from "@mantine/core";
import { useContext, useState } from "react";
import { Button } from "../ui-components/Button";
import classNames from "./Appointment.module.css";
import { CurrentProfile } from "../integrations/bonfhir";
import { FhirValue } from "@bonfhir/react/r4b";
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// import { Option } from "react-google-places-autocomplete/build/types";
import { useFhirTransactionMutation } from "@bonfhir/query/r4b";
import { NameUse, Practitioner, PractitionerQualification } from "@bonfhir/core/r4b";
import { states } from "../constants/states";
import { FaTrash, FaPlusCircle } from "react-icons/fa";

export function SettingsPage() {
  const savePractitionerTransaction = useFhirTransactionMutation();
  // Initial hooks
  const [deleteAccountModel, setDeleteAccountModel] = useState(false);
  const [edit, setEdit] = useState(false);
  const profile = useContext(CurrentProfile);
  const licenses = profile.qualification as unknown as {system: string, value: string, type: { coding: {code: string, display: string, system: string}[]}}[]
  // const profilePhoto = profile.photo && profile.photo[0] ? profile.photo[0] : undefined;
  // Local state for editable fields
  const [firstName, setFirstName] = useState(profile.name && profile.name[0].given && profile.name[0].given[0] || '');
  const [lastName, setLastName] = useState(profile.name && profile.name[0].family || '');
  // const [address, setAddress] = useState<Option | null>(null);
  const [license, setLicense] = useState<{state: string, licenseNumber: string}>({state: "", licenseNumber: ""});
  const saveSettings = () => {
  
    savePractitionerTransaction.mutate((transaction) => {
      const practitioner = profile as unknown as Practitioner;
      // if(address) {
      //   const secondary = address?.value?.structured_formatting?.secondary_text;
      //   const info = secondary ? secondary.split(", ") : [];
      //   const fhirAddress: Address = {
      //     text: address?.label,
      //     line: [address?.value.structured_formatting.main_text],
      //     city: info[0],
      //     state: info[1],
      //     country: info[2]
      //   };
      //   practitioner.address = [fhirAddress];
      // }
      const name = {use: "official" as NameUse, given: [firstName], family: lastName};
      practitioner.name = [name];
      transaction.update(practitioner);
      setEdit(false);
    });
  }

  const addLicense = () => {
    if(license.state && license.licenseNumber) {
      const qualification = {
        system: "http://terminology.hl7.org/ValueSet/v2-0338",
        value: license.licenseNumber,
        type: {
          coding: [
            {
              code: "MD",
              system: "http://terminology.hl7.org/ValueSet/v2-0338",
              display: "Medical License number"
            },
            {
              code: license.state,
              system: "http://hl7.org/fhir/us/core/ValueSet/us-core-usps-state",
              display: license.state
            }
          ]
        }
      } as unknown as PractitionerQualification;
      const identifier = {
        value: license.state,
        system: "http://hl7.org/fhir/us/core/ValueSet/us-core-usps-state",
      }
    savePractitionerTransaction.mutate((transaction) => {
      const practitioner = profile as unknown as Practitioner;
      practitioner.qualification = [...practitioner.qualification ?? [], qualification];
      practitioner.identifier = [...practitioner.identifier ?? [], identifier];
      transaction.update(practitioner);
      setLicense({state: "", licenseNumber: ""});
    })
    } else {
      alert("Please select a state and enter a license number to save a license")
    }
  }

  const removeLicense = (state: string, licenseNumber: string) => {
    savePractitionerTransaction.mutate((transaction) => {

      const practitioner = profile as unknown as Practitioner;

      let identifiers;
      if(practitioner.identifier?.length === 1) {
        identifiers = []
      } else {
        const index = practitioner.identifier?.findIndex(i => i.system === "http://hl7.org/fhir/us/core/ValueSet/us-core-usps-state" && i.value === state);
        if (index) {
          identifiers = index === 0 ? practitioner?.identifier?.slice(1) : [...practitioner.identifier?.slice(0, index) ?? [], ...practitioner.identifier?.slice(index + 1) ?? []];
        }
      }

      let qualifications;
      if(practitioner.qualification?.length === 1) {
        qualifications = []
      } else {
        const index = licenses?.findIndex((i) => i.system === "http://terminology.hl7.org/ValueSet/v2-0338" && i.value === licenseNumber && i.type.coding.find(c => c.system === "http://hl7.org/fhir/us/core/ValueSet/us-core-usps-state" && c.code === state));
        if (index) {
          qualifications = index === 0 ? practitioner?.qualification?.slice(1) : [...practitioner.qualification?.slice(0, index) ?? [], ...practitioner.qualification?.slice(index + 1) ?? []];
        }
      }


      practitioner.identifier = identifiers;
      practitioner.qualification = qualifications;
      transaction.update(practitioner);
    })
  }

  return (
    <Container fluid className={classNames.root}>
      <Grid>
        <Grid.Col span={3}>
          <Flex justify="start" w="100%">
            <Title>Profile settings</Title>
          </Flex>
          <Space h="lg" />
          {/* <Avatar alt={profilePhoto?.title} radius="100%" size="200" src={profilePhoto?.url}> */}
          <FhirValue options={{ style: "full" }} type="HumanName" value={profile.name} />
          <Space h="lg" />
          {/* <Switch color="black" label="Edit" checked={edit} onChange={() => setEdit(!edit)}/> */}
        </Grid.Col>
        <Grid.Col span={8}>
          <Stack gap="sm" align="start" w="100%">
            <Stack className={classNames.contentContainer} bg="backgroundPrimary">
              <Space h="lg" />
              <Flex justify="space-between" gap="md" w="100%">
                <Box flex={0} miw="35%">
                  <Table horizontalSpacing="xl">
                    <Table.Thead>
                      <Table.Th>
                        <Title size={20} style={{ whiteSpace: "nowrap" }}>
                          General information
                        </Title>
                      </Table.Th>
                    </Table.Thead>
                    <Table.Tbody>
                      <Table.Tr key={"generalInfoFirstName"}>
                        <Table.Td>
                          <b>First name</b>
                        </Table.Td>
                        <Table.Td>
                          {edit ? (
                            <TextInput value={firstName} onChange={(e) => setFirstName(e.currentTarget.value)} style={{minWidth: 200}}/>
                          ) : (
                            firstName
                          )}
                        </Table.Td>
                      </Table.Tr>
                      <Table.Tr key={"generalInfoLastName"}>
                        <Table.Td>
                          <b>Last name</b>
                        </Table.Td>
                        <Table.Td>
                          {edit ? (
                            <TextInput value={lastName} onChange={(e) => setLastName(e.currentTarget.value)} style={{minWidth: 200}} />
                          ) : (
                            lastName
                          )}
                        </Table.Td>
                      </Table.Tr>
                      {/* <Table.Tr key={"generalInfoLocation"}>
                        <Table.Td>
                          <b>Location</b>
                        </Table.Td>
                        <Table.Td>
                          {edit ? (
                            <GooglePlacesAutocomplete
                            apiKey="AIzaSyCE5WapeGIfInOAv0TEP8Kn_XiEpc2bdjQ"
                            minLengthAutocomplete={3}
                            selectProps={{
                                value: address,
                                onChange: setAddress,
                            }}
                            />
                          ) : (
                            <FhirValue type="Address" value={profile.address && profile.address[0]} />
                          )}
                        </Table.Td>
                      </Table.Tr> */}
                    {/* </Table.Tbody>
                  </Table>
                </Box>
              </Flex>
            </Stack>
          </Stack>
          <Stack gap="sm" align="start" w="100%">
            <Stack className={classNames.contentContainer} bg="backgroundPrimary">
              <Space h="lg" />
              <Flex justify="space-between" gap="md" w="100%">
                <Box flex={0} miw="35%">
                  <Table horizontalSpacing="xl">
                    <Table.Thead>
                      <Table.Th>
                        <Title size={20} style={{ whiteSpace: "nowrap" }}>
                          Account infomation
                        </Title>
                      </Table.Th>
                    </Table.Thead>
                    <Table.Tbody> */}
                      <Table.Tr key={"accountInfoEmail"}>
                        <Table.Td>
                          <b>Email address</b>
                        </Table.Td>
                        <Table.Td style={{ whiteSpace: "nowrap" }}>
                          {profile.telecom?.find((t) => t.system === "email")?.value}
                        </Table.Td>
                      </Table.Tr>
                      <Table.Tr key={"accountInfoPassword"}>
                        <Table.Td>
                          <b>Password</b>
                        </Table.Td>
                        <Table.Td>
                          {edit ? (
                            <Button 
                              onClick={() => console.log("send password reset email")} 
                              variant="outline" 
                              color="black">
                              Send reset password email
                            </Button>
                          ) : (
                            "*********"
                          )}
                        </Table.Td>
                      </Table.Tr>
                    </Table.Tbody>
                  </Table>
                </Box>
              </Flex>
            </Stack>
          </Stack>
        </Grid.Col>
        <Grid.Col span={1}>
          <Button onClick={() => setEdit(!edit)} variant="outline" color="black">
            Edit
          </Button>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex justify="start" w="100%">
            <Title>Medical licenses</Title>
          </Flex>
          <Space h="lg" />
          {/* <Avatar alt={profilePhoto?.title} radius="100%" size="200" src={profilePhoto?.url}> */}
          {/* <FhirValue options={{ style: "full" }} type="HumanName" value={profile.name} /> */}
          {/* </Avatar> */}
        </Grid.Col>
        <Space h="lg" />
        <Space h="lg" />
        <Grid.Col span={8}>
          <Stack gap="sm" align="start" w="100%">
            <Stack className={classNames.contentContainer} bg="backgroundPrimary">
              <Space h="lg" />
              <Flex justify="space-between" gap="md" w="100%">
                <Box flex={0} miw="35%">
                  <Table horizontalSpacing="xl">
                    <Table.Thead>
                    <Table.Th>
                        <Title size={20} style={{ whiteSpace: "nowrap" }}>
                          State
                        </Title>
                      </Table.Th>
                      <Table.Th>
                        <Title size={20} style={{ whiteSpace: "nowrap" }}>
                          Medical License number
                        </Title>
                      </Table.Th>
                    </Table.Thead>
                    <Table.Tbody>
                      
                      {licenses?.map((l, i) => {
                        if(l.system === "http://terminology.hl7.org/ValueSet/v2-0338") {
                          const stateCode = l.type.coding.find(c => c.system === "http://hl7.org/fhir/us/core/ValueSet/us-core-usps-state");
                          const state = stateCode?.code ?? "";
                          return (<Table.Tr key={"license-" + i}>
                              <Table.Td>
                              {state}
                            </Table.Td>
                            <Table.Td>
                              <b>{l.value}</b>
                            </Table.Td>
                            <FaTrash onClick={() => removeLicense(state, l.value)} style={{marginTop: 10, cursor: "pointer"}} />

                          </Table.Tr>)
                          }
                        })}
                        <Space h="lg" />
                        <Table.Tr key={"license-title"}>
                          <Table.Th>
                            <Title size={20} style={{ whiteSpace: "nowrap" }}>
                              Add license
                            </Title>
                          </Table.Th>
                          {/* <Table.Td>
                          <Button onClick={() => addLicense()} variant="outline" color="black">
                            Save
                          </Button>
                          </Table.Td> */}
                        </Table.Tr>
                        <Table.Tr key={"new-license"}>
                              <Table.Td>
                                <TextInput
                                  value={license?.licenseNumber}
                                  onChange={(e) => setLicense({ ...license, licenseNumber: e.currentTarget.value})}
                                  style={{minWidth: 200}}
                                  label="License Number"
                                  placeholder="1234"
                                />
                            </Table.Td>
                            <Table.Td>
                              <Select
                                value={license?.state} 
                                onChange={(e) => setLicense({ ...license, state: e ?? ""})} style={{minWidth: 200}}
                                label="Pick a state"
                                placeholder="State"
                                data={states}
                              />
                            </Table.Td>
                            <FaPlusCircle onClick={() => addLicense()} size={20} style={{cursor: "pointer", marginTop: 35}} />
                          </Table.Tr>
                    </Table.Tbody>
                  </Table>
                </Box>
              </Flex>
            </Stack>
          </Stack>
        </Grid.Col>

      </Grid>
      <Space h="lg" />
        {edit && (<Flex justify="center">
      <Button onClick={() => saveSettings()} variant="outline" color="black">
          Save
        </Button>
        {/* <Button onClick={() => setDeleteAccountModel(true)} variant="outline" color="red">
          Delete account
        </Button> */}
      </Flex>)}

      <Modal
        opened={deleteAccountModel}
        onClose={() => {
          setDeleteAccountModel(false);
        }}
        size="lg"
        p="xl">
        <Flex justify="center">
          <Stack w="70%" p="lg" gap="lg" align="center" justify="space-between">
            <Title ta="center" order={2}>
              Please contact us to delete your account.
            </Title>
            <Text c="textAlert">
              To deactive/delete your account please contact us at <a href="support@join-cascade.com">support@join-cascade.com</a>
            </Text>
          </Stack>
        </Flex>
      </Modal>
    </Container>
  );
}

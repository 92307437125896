import { Code, Container, Text, Title } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { useContext } from "react";
import { AppConfigContext } from "../app-configuration";

export function DebugPage() {
  const config = useContext(AppConfigContext);

  return (
    <Container fluid>
      <Title order={1}>Debug Page</Title>
      <Text style={{ display: "inline-flex", justifyContent: "center" }}>
        <IconAlertTriangle size={24} />
        This is for development purposes only
        <IconAlertTriangle size={24} />
      </Text>
      <br />
      <Code block>{JSON.stringify(config, null, 2)}</Code>
    </Container>
  );
}

import {
  Appointment,
  AppointmentFhirSearchBuilder,
  reference,
} from "@bonfhir/core/r4b";
import { Stack, Text, Title } from "@mantine/core";
import { useCallback, useContext } from "react";
import { CurrentProfile } from "../integrations/bonfhir.tsx";
import { AppointmentTable } from "../ui-components/AppointmentList.tsx";

import { useNavigate } from "react-router-dom";
import { Button } from "../ui-components/Button.tsx";


export function PastAppointments() {
  const practitioner = useContext(CurrentProfile);
  const navigate = useNavigate();

  const pastAppointments = useCallback(
    (builder: AppointmentFhirSearchBuilder): AppointmentFhirSearchBuilder => {
      return builder
        .actor(reference(practitioner))
        ._include("Appointment", "patient")
        .filterSearchParameter(`status eq "fulfilled"`)
        ._sort(`-date`)
        ._count(50);
    },
    [practitioner],
  );

  return (
    <Stack gap="xl" px="lg" py="xl">
      <Title order={2}>Past visits</Title>
      <Text c="textSecondary">
        Here you will find requests from patients whom you have treated in the
        past.
      </Text>

      <Stack mt="6rem">
        <Text c="textSecondary" size="sm" fw="bold">
          PREVIOUS VISITS
        </Text>
        <AppointmentTable
          query={pastAppointments}
          appointmentActions={(appointment: Appointment) => {
            return (
              <Button
                onClick={() => {
                  navigate(`/appointment/${appointment.id}`);
                }}
                variant="outline"
                color="black"
              >
                View Summary
              </Button>
            );
          }}
        />
      </Stack>
    </Stack>
  );
}

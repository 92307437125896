import { createContext } from "react";
import { z } from "zod";

function populatedString() {
  return z
    .string()
    .trim()
    .min(1)
    .describe("A non-empty string with human-readable content");
}

export const OpenIdConnectConfiguration = z.object({
  clientId: populatedString(),
  discoveryURL: populatedString().url(),
  scopes: z.array(z.string().trim().min(1)).optional(),
});
export type OpenIdConnectConfiguration = z.infer<
  typeof OpenIdConnectConfiguration
>;

export const AccessTokenExchangerConfiguration = z.object({
  clientId: populatedString(),
  tokenURL: populatedString().url(),
});
export type AccessTokenExchangerConfiguration = z.infer<
  typeof AccessTokenExchangerConfiguration
>;

export const AppConfiguration = z.object({
  /**
   * The URL of the API server
   */
  serverUrl: populatedString().url(),

  /**
   * The OpenID Connect configuration the application will use to authenticate
   * the practitioner.
   */
  openIdConnect: OpenIdConnectConfiguration,

  /**
   * The URL of the Medplum server the application should use to fetch and
   * update FHIR resources.
   */
  fhirServerBaseURL: populatedString().url(),

  /**
   * The configuration for an service that will exchange an OpenID Connect
   * access token for another. This service be be able to communicate directly
   * with the OpenID Connect provider to validate the access token given to it.
   *
   * @see {@link https://www.medplum.com/docs/auth/methods/token-exchange}
   */
  tokenExchanger: AccessTokenExchangerConfiguration,

  /**
   * The version of the Cascade Provider application.
   */
  version: populatedString()
    .describe("The version of the Cascade Provider application")
    .optional()
    .nullish(),

  /**
   * The environment the application is running in.
   */
  environment: z
    .enum(["default", "staging", "branch", "old-dev", "production"])
    .optional(),

  /**
   * The PostHog API key
   */
  posthogApiKey: populatedString().optional(),
});
export type AppConfiguration = z.infer<typeof AppConfiguration>;

/**
 * The default configuration for the Cascade Provider application is intended
 * to be used entirely locally. The local FHIR server in `apps/fhir-server` is
 * a single-container Medplum instance that can support OpenID Connect. While
 * currently hard-coded to use a separate identity provider, the configuration
 * should easily be adapted to omit the `tokenExchanger` configuration and
 * use the local Medplum instance as an identity provider directly.
 */
const defaultDevelopmentConfiguration = {
  fhirServerBaseURL: "http://localhost:8103/fhir/R4",
  openIdConnect: {
    clientId: "f54370de-eaf3-4d81-a17e-24860f667912",
    discoveryURL: "http://localhost:8103",
  },
  tokenExchanger: {
    clientId: "f54370de-eaf3-4d81-a17e-24860f667912",
    tokenURL: "http://localhost:8103/oauth2/token",
  },
  version: "local-development",
  environment: "default",
  serverUrl: "http://localhost:3000",
  posthogApiKey: "",
} as const satisfies AppConfiguration;

const staging = "staging.join-cascade.com" as const;
const stagingConfiguration = {
  fhirServerBaseURL: `https://api.medplum.${staging}/fhir/R4`,
  openIdConnect: {
    clientId: "cCsvlzVbWi6YVw9fvne2wu1wwn9XW4NT",
    discoveryURL: "https://dev-o87jeqrlpyw04ehe.us.auth0.com",
  },
  tokenExchanger: {
    clientId: "63131c8a-0469-40af-80cc-c42f3e2df3a9",
    tokenURL: `https://api.medplum.${staging}/oauth2/token`,
  },
  version: __CASCADE_REPOSITORY_COMMIT_SHA__,
  environment: "staging",
  serverUrl: `https://api.${staging}`,
  posthogApiKey: "phc_oDXnoZmMBGD5ORpP6zqe4shqd0esVaow3oQn3mtfMN8",
} as const satisfies AppConfiguration;

const production = "trycade.com" as const;
const productionConfiguration = {
  fhirServerBaseURL: `https://api.medplum.${production}/fhir/R4`,
  openIdConnect: {
    clientId: "cFk1OfAm3XAqKaPrmz0Z0ZoUyXQlGXTU",
    discoveryURL: "https://trycade.us.auth0.com",
  },
  tokenExchanger: {
    clientId: "25f4fc31-0f14-4a63-bc82-82a8c5d00444",
    tokenURL: `https://api.medplum.${production}/oauth2/token`,
  },
  version: __CASCADE_REPOSITORY_COMMIT_SHA__,
  serverUrl: `https://api.${production}`,
  environment: "production",
  posthogApiKey: "phc_jyS2VYY2b3mWbqNW2xZMBSzQFNjBjiNgxDmTv6psYNz",
} as const satisfies AppConfiguration;

const getBranchConfiguration = (branchName: string): AppConfiguration => {
  const branch = `${branchName}.branch.join-cascade.com`;
  return {
    fhirServerBaseURL: `https://TODO.${branch}/fhir/R4`,
    openIdConnect: {
      clientId: "TODO",
      discoveryURL: `https://TODO.${branch}`,
    },
    tokenExchanger: {
      clientId: "TODO",
      tokenURL: `https://TODO.${branch}/oauth2/token`,
    },
    version: __CASCADE_REPOSITORY_COMMIT_SHA__,
    serverUrl: `https://api.${branch}`,
    environment: "branch",
    posthogApiKey: "",
  } as const satisfies AppConfiguration;
};

const oldDevConfiguration = {
  fhirServerBaseURL: "https://api.medplum-dev.join-cascade.com/fhir/R4",
  openIdConnect: {
    clientId: "MYuUMnT9IlHMMntZc9bTOwQZewnppxn9",
    discoveryURL: "https://dev-o87jeqrlpyw04ehe.us.auth0.com",
  },
  tokenExchanger: {
    clientId: "5ef409d7-7bdb-4262-947f-b27802577228",
    tokenURL: "https://api.medplum-dev.join-cascade.com/oauth2/token",
  },
  version: __CASCADE_REPOSITORY_COMMIT_SHA__,
  serverUrl: "https://dev.api.join-cascade.com",
  environment: "old-dev",
  posthogApiKey: "",
} as const satisfies AppConfiguration;

const buildMode = import.meta.env.MODE;

const getConfiguration = () => {
  // Helps with local development
  if (window.location.hostname === "localhost") {
    return stagingConfiguration;
  }

  if (buildMode === "staging") {
    return stagingConfiguration;
  } else if (buildMode === "oldDev") {
    return oldDevConfiguration;
  } else if (buildMode === "branch") {
    return getBranchConfiguration(import.meta.env.VITE_BRANCH_NAME);
  } else if (buildMode === "production") {
    return productionConfiguration;
  } else {
    return defaultDevelopmentConfiguration;
  }
};

export const appConfiguration = getConfiguration();
export const AppConfigContext = createContext<AppConfiguration>(
  defaultDevelopmentConfiguration,
);

// TODO remove this? we should be using the context
export const SERVER_URL = appConfiguration.serverUrl; // "http://localhost:3000"; //

export const Microphone = ({ width = 24, height = 24, stroke = "#495057" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 8.20133V10C20 14.4183 16.4183 18 12 18M4 8.20133V10C4 14.4183 7.58172 18 12 18M12 18L12 23M7.42859 22.9998L12 23M16.5714 22.9998L12 23M11.9757 15C9.76661 15 7.97575 13.2091 7.97575 11V5C7.97575 2.79086 9.76661 1 11.9757 1C14.1849 1 15.9757 2.79086 15.9757 5V11C15.9757 13.2091 14.1849 15 11.9757 15Z"
      stroke={stroke}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { appRouter } from "./app-router.tsx";
import { appConfiguration } from "./app-configuration.ts";
import * as Sentry from "@sentry/react";
import { PostHogProvider} from 'posthog-js/react'


// only report errors in production and staging
if (appConfiguration?.environment === "production" || appConfiguration?.environment === "staging") {
  console.log("Initializing Sentry");
  Sentry.init({
    dsn: "https://296378d3dc1e05acee632713e299650f@o4507743287508992.ingest.us.sentry.io/4507749296635904",
    integrations: [
    ],
    environment: appConfiguration.environment, // Supply the environment to Sentry
  });
}

const posthogOptions = {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'identified_only' as const
}


createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <PostHogProvider 
      apiKey={appConfiguration.posthogApiKey}
      options={posthogOptions}
    ></PostHogProvider>
    <RouterProvider router={appRouter} />
  </StrictMode>,
);

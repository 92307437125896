import type { User as OpenIdUser } from "oidc-client-ts";
import { useEffect, type ReactNode } from "react";
import { useAuth, type AuthContextProps } from "react-oidc-context";

export type WhenAuthenticatedProps = {
  loading?: ReactNode;
  children?(
    authenticatedUser: OpenIdUser,
    signout: AuthContextProps["signoutRedirect"],
  ): ReactNode;
};

export function WhenAuthenticated(props: WhenAuthenticatedProps) {
  const auth = useAuth();

  // Main effect handling handling authentication
  useEffect(() => {
    // If the auth state has changed, the user is authenticated and we have a
    // token, it's likely that we've just been redirected back from the OpenID
    // Connect provider with a token. In this case, we should clear the URL
    // of any OpenID Connect params.
    if (auth.isAuthenticated && auth.user?.access_token) {
      // Clear the URL for any OpenID params
      history.pushState(null, "", location.href.split("?")[0]);
      return;
    }

    // Do nothing if the user is currently authenticating
    if (auth.isLoading) return;

    // If the user is authenticated but we don't have a token, it's possible
    // the authentication is in a broken state which is handled below.
    // TODO: Consider emitting an error here for an optional handler.
    if (auth.isAuthenticated) return;

    // Otherwise, the user is not authenticated, no authentication is in
    // progress so and we should redirect to the OpenID Connect provider's
    // signin page.
    auth.signinRedirect();
  }, [auth]);

  if (auth.isLoading) {
    return <>{props.loading ?? "Loading..."}</>;
  }

  if (auth.error) {
    return <>{auth.error.message}</>;
  }

  if (auth.isAuthenticated) {
    if (!auth.user) {
      return (
        <>
          User is authenticated but no user record was returning by the OpenID
          Connect provider.
        </>
      );
    }

    return (
      <>
        {typeof props.children === "function"
          ? props.children(auth.user, auth.signoutSilent)
          : auth.user.access_token}
      </>
    );
  }

  return <></>;
}

// function useOpenIdIUser(): OpenIdUser {
//   const auth = useAuth();
//   if (!auth.isAuthenticated || !auth.user) {
//     throw new Error(
//       "User is not authenticated! `useOpenIdUser` may only be called from a child of `<WhenAuthenticated />`.",
//     );
//   }

//   return auth.user;
// }

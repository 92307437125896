export const BackPain = ({ width = 40, height = 40, stroke = "#343A40" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6667 7.83333V5.33333M13.6667 21.0837V18.6668M19.6667 34.5V32M15.4999 3.66667C15.4999 2.7462 16.2461 2 17.1666 2H28.8333C29.7537 2 30.5 2.7462 30.5 3.66667V4.50015C31.8807 4.50015 33 5.61943 33 7.00015C33 8.38087 31.8807 9.50015 30.5 9.50015V10.3333C30.5 11.2538 29.7537 12 28.8333 12H17.1666C16.2461 12 15.4999 11.2538 15.4999 10.3333V9.50015C14.1192 9.50012 13 8.38085 13 7.00015C13 5.61947 14.1192 4.50018 15.4999 4.50015V3.66667ZM9.49993 17C9.49993 16.0795 10.2461 15.3333 11.1666 15.3333H22.8333C23.7537 15.3333 24.5 16.0795 24.5 17V17.8335C25.8807 17.8335 27 18.9528 27 20.3335C27 21.7142 25.8807 22.8335 24.5 22.8335V23.6667C24.5 24.5872 23.7537 25.3333 22.8333 25.3333H11.1666C10.2461 25.3333 9.49993 24.5872 9.49993 23.6667V22.8335C8.11925 22.8335 7 21.7142 7 20.3335C7 18.9528 8.11925 17.8335 9.49993 17.8335V17ZM15.4999 30.3333C15.4999 29.4128 16.2461 28.6667 17.1666 28.6667H28.8333C29.7537 28.6667 30.5 29.4128 30.5 30.3333V31.1668C31.8807 31.1668 33 32.2862 33 33.6668C33 35.0475 31.8807 36.1668 30.5 36.1668V37C30.5 37.9205 29.7537 38.6667 28.8333 38.6667H17.1666C16.2461 38.6667 15.4999 37.9205 15.4999 37V36.1668C14.1192 36.1668 13 35.0475 13 33.6668C13 32.2862 14.1192 31.1668 15.4999 31.1668V30.3333Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Exit = ({ width = 24, height = 24, stroke = "#495057" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 7V2C19 1.44772 18.5523 1 18 1H3C2.44772 1 2 1.44772 2 2V22C2 22.5523 2.44772 23 3 23H18C18.5523 23 19 22.5523 19 22V17.5M22 12.5H10M10 12.5C10 12.6187 10.0421 12.7375 10.1263 12.8322L13.831 17M10 12.5C10 12.3813 10.0421 12.2625 10.1263 12.1678L13.831 8"
      stroke={stroke}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

import { useFhirSearch, useFhirSearchOne } from "@bonfhir/query/r4b";
import { FhirQueryLoader, FhirQuestionnaire } from "@bonfhir/react/r4b";
import { Container, Select, Text, Title } from "@mantine/core";
import { useState } from "react";
// import { AppointmentBanner } from "../ui-components/AppointmentBanner";
import { ConsultationState } from "../ui-components/ConsultationState";

export function TestingPage() {
  const appointmentId = "3da1dacd-2488-4ba9-b3bd-6b325e40d1cd";

  const appointmentRequest = useFhirSearchOne("Appointment", (query) => {
    return query._id(appointmentId);
  });

  const questionnairesQuery = useFhirSearch("Questionnaire", (query) =>
    query.status("active"),
  );

  const [selectedQuestionnaireURL, setSelectedQuestionnaireURL] = useState<
    string | null
  >(null);

  return (
    <Container fluid>
      {/* <AppointmentBanner appointment={appointmentRequest.data} /> */}
      <ConsultationState appointment={appointmentRequest.data} />
      <br />
      <section>
        <Title order={1}>FHIR Questionnaires</Title>
        <FhirQueryLoader query={questionnairesQuery}>
          {(questionnaires) =>
            questionnaires.total === 0 ? (
              <Text>Found no active questionnaires</Text>
            ) : (
              <>
                <Text>Found {questionnaires.total} active questionnaires</Text>
                <Select
                  data={questionnaires.searchMatch().map((q) => ({
                    label: q.name ?? q.url ?? q.id,
                    value: q.url!,
                  }))}
                  label="Questionnaire to view"
                  onChange={setSelectedQuestionnaireURL}
                  value={selectedQuestionnaireURL}
                />
                {selectedQuestionnaireURL && (
                  <FhirQuestionnaire source={selectedQuestionnaireURL} />
                )}
              </>
            )
          }
        </FhirQueryLoader>
      </section>
    </Container>
  );
}

export const SkinRash = ({ width = 41, height = 40, fill = "#FFF" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3592 22.9825C15.3596 23.5348 14.9123 23.9829 14.36 23.9833C13.8077 23.9838 13.3596 23.5364 13.3592 22.9842C13.3587 22.4319 13.806 21.9838 14.3583 21.9833C14.9106 21.9829 15.3587 22.4302 15.3592 22.9825ZM20.36 23.9784C20.3604 24.5307 19.9131 24.9788 19.3608 24.9792C18.8085 24.9797 18.3604 24.5323 18.36 23.9801C18.3595 23.4278 18.8069 22.9797 19.3591 22.9792C19.9114 22.9788 20.3595 23.4261 20.36 23.9784ZM16.3641 28.9817C16.9163 28.9812 17.3637 28.5332 17.3632 27.9809C17.3628 27.4286 16.9147 26.9812 16.3624 26.9817C15.8101 26.9822 15.3628 27.4302 15.3632 27.9825C15.3637 28.5348 15.8118 28.9822 16.3641 28.9817ZM22.3624 26.9768C22.3629 27.5291 21.9155 27.9772 21.3632 27.9776C20.811 27.9781 20.3629 27.5307 20.3624 26.9784C20.362 26.4261 20.8093 25.9781 21.3616 25.9776C21.9139 25.9772 22.362 26.4245 22.3624 26.9768ZM23.3665 31.976C23.9188 31.9755 24.3662 31.5274 24.3657 30.9751C24.3652 30.4229 23.9172 29.9755 23.3649 29.976C22.8126 29.9764 22.3652 30.4245 22.3657 30.9768C22.3662 31.5291 22.8142 31.9764 23.3665 31.976ZM26.3624 26.9735C26.3629 27.5258 25.9155 27.9739 25.3632 27.9743C24.811 27.9748 24.3629 27.5274 24.3624 26.9751C24.362 26.4229 24.8093 25.9748 25.3616 25.9743C25.9139 25.9739 26.362 26.4212 26.3624 26.9735ZM18.3673 32.9801C18.9196 32.9796 19.367 32.5315 19.3665 31.9792C19.3661 31.427 18.918 30.9796 18.3657 30.9801C17.8134 30.9805 17.3661 31.4286 17.3665 31.9809C17.367 32.5332 17.8151 32.9805 18.3673 32.9801ZM14.3657 30.9833C14.3662 31.5356 13.9188 31.9837 13.3665 31.9842C12.8142 31.9846 12.3662 31.5373 12.3657 30.985C12.3653 30.4327 12.8126 29.9846 13.3649 29.9842C13.9172 29.9837 14.3653 30.4311 14.3657 30.9833ZM11.3632 27.9858C11.9155 27.9853 12.3629 27.5373 12.3624 26.985C12.362 26.4327 11.9139 25.9853 11.3616 25.9858C10.8093 25.9862 10.362 26.4343 10.3624 26.9866C10.3629 27.5389 10.811 27.9862 11.3632 27.9858Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8765 2C19.7049 2 20.3765 2.67157 20.3765 3.5V19H22.3765V6.5C22.3765 5.67157 23.0481 5 23.8765 5C24.7049 5 25.3765 5.67157 25.3765 6.5V22.0855C25.3765 23.9898 27.7861 24.8165 28.9552 23.3133L32.1417 19.2164C32.7563 18.4262 33.954 18.4367 34.5547 19.2376C34.9268 19.7338 34.9597 20.4064 34.6378 20.9366L27.4338 32.7992C25.4727 36.0283 21.9686 38 18.1906 38C12.2181 38 7.37646 33.1584 7.37646 27.1859V12.5C7.37646 11.6716 8.04806 11 8.87646 11C9.70486 11 10.3765 11.6716 10.3765 12.5V21.1176H12.3765V6.5C12.3765 5.67157 13.0481 5 13.8765 5C14.7049 5 15.3765 5.67157 15.3765 6.5V19H17.3765V3.5C17.3765 2.67157 18.0481 2 18.8765 2ZM15.3802 3.33856C15.4645 1.4805 16.9976 0 18.8765 0C20.7554 0 22.2885 1.4805 22.3728 3.33856C22.8284 3.1215 23.3383 3 23.8765 3C25.8095 3 27.3765 4.567 27.3765 6.5V22.0855L30.563 17.9885C31.9872 16.1574 34.7628 16.1817 36.1547 18.0376C37.0171 19.1874 37.0933 20.7462 36.3472 21.9747L29.1432 33.8373C26.8195 37.6637 22.6673 40 18.1906 40C11.1136 40 5.37646 34.2629 5.37646 27.1859V12.5C5.37646 10.567 6.94346 9 8.87646 9C9.41326 9 9.92186 9.1208 10.3765 9.3368V6.5C10.3765 4.567 11.9435 3 13.8765 3C14.4147 3 14.9246 3.1215 15.3802 3.33856Z"
      fill={fill}
    />
  </svg>
);
